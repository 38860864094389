// import React from "react";
// import "../Style/Cart.css";
// import "../Style/Navbar.css";
// import list from "./Data";
// import HomeBaseCartDesign from "../UtilsComponent/cardComponent/HomeBaseCartDesign";

// export default function Cart() {
//   return (
//     <>
//       <div className="card_back_main_div">
//         <div className="cart_back_images1">
//           <img src={require("../Images/balun.png")} alt="" />
//         </div>
//         <div className="card_navbar_back">
//           <div className="card_items">
//             <div className="card_heading">
//               <span>Popular Places</span>
//             </div>
//             <ul className="menu">
//               <div className="menu_back_image">
//                 <li style={{ color: "white" }}>Beach</li>
//               </div>
//               <li>Mountain</li>
//               <li>WaterFails</li>
//               <li className="view_all">
//                 <b>View all</b>{" "}
//                 <img
//                   className="arrow_image"
//                   src={require("../Images/blueArrow.png")}
//                   alt="#"
//                 />
//               </li>
//             </ul>
//             <ul className="home_menu">
//               <div className="menu_back_image">
//                 <li style={{ color: "white" }}>Beach</li>
//               </div>
//               <li>Mountain</li>
//               <li className="view_all">
//                 <b>View all</b>{" "}
//                 <img
//                   className="arrow_image"
//                   src={require("../Images/blueArrow.png")}
//                   alt="#"
//                 />
//               </li>
//             </ul>
//           </div>
//           <div className="cart_view_container">
//             <div className="card_div_row1">
//               <div className="card_back">
//                 <HomeBaseCartDesign />
//               </div>
//               <div className="card_back">
//                 <HomeBaseCartDesign />
//               </div>
//               <div className="card_back">
//                 <HomeBaseCartDesign />
//               </div>
//             </div>

//             <div className="card_div_row2">
//               <div className="card_back">
//                 <HomeBaseCartDesign />
//               </div>
//               <div className="card_back">
//                 <HomeBaseCartDesign />
//               </div>
//               <div className="card_back">
//                 <HomeBaseCartDesign />
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* <div className="cart_back_images2">
//           <img src={require("../Images/pink_camera.jpg")} alt="" />
//         </div> */}
//       </div>
//     </>
//   );
// }
import React, { useEffect, useState } from "react";

import "../Style/Cart.css";
import ImgBox from "./ImgBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "white" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "white" }}
      onClick={onClick}
    />
  );
}

export default function Cart() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  // const data = [
  //   {
  //     image:
  //       "https://img.staticmb.com/mbcontent/images/uploads/2022/8/bhu%20naksha%20rajasthan.jpg",
  //     visitor: 235,
  //     rating: 4.5,
  //     name: "Chadar Trek",
  //     place: "Leh Ladakh",
  //     activities: ["Sunrise", "Water Sports", "Sunset", "Swimming"],
  //   },
  //   {
  //     image:
  //       "https://img.staticmb.com/mbcontent/images/uploads/2022/8/bhu%20naksha%20rajasthan.jpg",
  //     visitor: 500,
  //     rating: 4.7,
  //     name: "Taj Mahal",
  //     place: "Agra, Uttar Pradesh",
  //     activities: ["Sightseeing", "Photography", "History Tours"],
  //   },
  //   {
  //     image:
  //       "https://img.staticmb.com/mbcontent/images/uploads/2022/8/bhu%20naksha%20rajasthan.jpg",
  //     visitor: 350,
  //     rating: 4.6,
  //     name: "Amber Fort",
  //     place: "Jaipur, Rajasthan",
  //     activities: ["Historical Tours", "Elephant Ride", "Architecture"],
  //   },
  //   {
  //     image:
  //       "https://img.staticmb.com/mbcontent/images/uploads/2022/8/bhu%20naksha%20rajasthan.jpg",
  //     visitor: 600,
  //     rating: 4.8,
  //     name: "Baga Beach",
  //     place: "Goa",
  //     activities: ["Beach Parties", "Water Sports", "Nightlife"],
  //   },
  //   {
  //     image:
  //       "https://img.staticmb.com/mbcontent/images/uploads/2022/8/bhu%20naksha%20rajasthan.jpg",
  //     visitor: 280,
  //     rating: 4.4,
  //     name: "Varanasi Ghats",
  //     place: "Varanasi, Uttar Pradesh",
  //     activities: ["Spiritual Retreat", "Boat Ride", "Aarti Ceremony"],
  //   },
  //   {
  //     image:
  //       "https://img.staticmb.com/mbcontent/images/uploads/2022/8/bhu%20naksha%20rajasthan.jpg",
  //     visitor: 450,
  //     rating: 4.7,
  //     name: "Backwaters of Kerala",
  //     place: "Kerala",
  //     activities: ["Houseboat Cruise", "Ayurvedic Retreat", "Wildlife Tours"],
  //   },
  // ];
  const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 845);
  useEffect(() => {
    async function fetchInfo() {
      try {
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          "https://orca-app-pgxkr.ondigitalocean.app/website/place/",
          requestOptions
        );
        const result = await response.json();
        if (response.ok) {
          console.log("result", result);
          setData(result);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, []);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 845);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="">
      {!isMobile ? (
        <div className="container">
          <div className="places-heading">
            <p className="heading">Popular Places</p>
            <div className="options">
              <span>Beach</span>
              <span>Mountain</span>
              <span className="hide-on-phone">Waterfall</span>
              <div className="viewAll">
                View All
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="12"
                  viewBox="0 0 40 16"
                  fill="none"
                >
                  <path
                    d="M39.7071 8.70711C40.0976 8.31659 40.0976 7.68342 39.7071 7.2929L33.3431 0.928935C32.9526 0.538411 32.3195 0.538411 31.9289 0.928935C31.5384 1.31946 31.5384 1.95262 31.9289 2.34315L37.5858 8L31.9289 13.6569C31.5384 14.0474 31.5384 14.6805 31.9289 15.0711C32.3195 15.4616 32.9526 15.4616 33.3431 15.0711L39.7071 8.70711ZM-8.74228e-08 9L39 9L39 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="#2DE3AD"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="img-container">
            {data.map((item, index) => (
              <ImgBox
                key={index}
                activities={item.activities}
                image={item.image}
                visitor={item.visitors_count}
                rating={item.rating}
                name={item.name}
                place={item.city}
                description={item.description}
                index={index}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="places-heading">
            <p className="heading">Popular Places</p>
            <div className="options">
              <span>Beach</span>
              <span>Mountain</span>
              <div className="viewAll">
                View All
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="12"
                  viewBox="0 0 40 16"
                  fill="none"
                >
                  <path
                    d="M39.7071 8.70711C40.0976 8.31659 40.0976 7.68342 39.7071 7.2929L33.3431 0.928935C32.9526 0.538411 32.3195 0.538411 31.9289 0.928935C31.5384 1.31946 31.5384 1.95262 31.9289 2.34315L37.5858 8L31.9289 13.6569C31.5384 14.0474 31.5384 14.6805 31.9289 15.0711C32.3195 15.4616 32.9526 15.4616 33.3431 15.0711L39.7071 8.70711ZM-8.74228e-08 9L39 9L39 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="#2DE3AD"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="backgroungBlue">
            <Slider {...settings}>
              {data.map((item, index) => (
                <ImgBox
                  key={index}
                  activities={item.activities}
                  image={item.image}
                  visitor={item.visitors_count}
                  rating={item.rating}
                  name={item.name}
                  place={item.city}
                  description={item.description}
                  index={index}
                />
              ))}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
}
