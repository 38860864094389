// import React, { useEffect, useState } from "react";
// import "../Style/MobileAppFeaturesTwo.css";
// import CustomImageTextButton from "../Components/CustomTextImageButton";

// export default function MobileAppFeatures() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

//   useEffect(() => {
//     function handleResize() {
//       setIsMobile(window.innerWidth <= 992);
//     }

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   console.log("isMobile", isMobile);

//   const handleButtonClick = () => {
//     alert("Button clicked!");
//   };

//   return (
//     <>
//       {isMobile ? (
//         <div className="mobile_main_container_with_icon2">
//           <div className="mobile_side_icon2">
//             <img src={require("../Images/cicle1.png")} alt="#" />
//           </div>
//           <div className="mobile_main_container2">
//             <div className="mobile_images_div2">
//               <img
//                 className="mob3_img"
//                 src={require("../Images/mobile3.png")}
//                 alt=""
//               />
//               <img
//                 className="mob4_img"
//                 src={require("../Images/mobile4.png")}
//                 alt=""
//               />
//             </div>
//             <div className="mobile_text2">
//               <p>Learn and Grow</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <p>Expert Guided Expeditions</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <div className="button">
//                 <CustomImageTextButton
//                   text="Learn More"
//                   imageSrc={require("../Images/Arrow 4.png")}
//                   height="auto"
//                   width="auto"
//                   onClick={handleButtonClick}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div>
//           <div className="mobile_main_container_with_icon2">
//             <div className="mobile_side_icon2">
//               <img src={require("../Images/cicle1.png")} alt="#" />
//             </div>
//             <div className="mobile_main_container2">
//               <div className="mobile_text2">
//                 <p>Learn and Grow</p>
//                 <li className="bullet-list-item">
//                   <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                   dummy text of the printing and typesetting industry. Lorem
//                   Ipsum has been the industry's standard dummy text Lorem Ipsum
//                   is simply dummy text of the printing and typesetting industry.
//                   Lorem Ipsum has been the industry's stand
//                 </li>
//                 <p>Expert Guided Expeditions</p>
//                 <li className="bullet-list-item">
//                   <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                   dummy text of the printing and typesetting industry. Lorem
//                   Ipsum has been the industry's standard dummy text Lorem Ipsum
//                   is simply dummy text of the printing and typesetting industry.
//                   Lorem Ipsum has been the industry's stand
//                 </li>
//                 <div className="button">
//                   <CustomImageTextButton
//                     text="Learn More"
//                     imageSrc={require("../Images/Arrow 4.png")}
//                     height="auto"
//                     width="auto"
//                     onClick={handleButtonClick}
//                   />
//                 </div>
//               </div>
//               <div className="mobile_images_div2">
//                 <img
//                   className="mob3_img"
//                   src={require("../Images/mobile3.png")}
//                   alt=""
//                 />
//                 <img
//                   className="mob4_img"
//                   src={require("../Images/mobile4.png")}
//                   alt=""
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }
import React, { useEffect, useState } from "react";
import "../Style/MobileAppFeatures.css";
import CustomImageTextButton from "../Components/CustomTextImageButton";
export default function MobileAppFeatures() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 928);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 928);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log("isMobile", isMobile);

  const handleButtonClick = () => {
    alert("Button clicked!");
  };
  return (
    <div className="container">
      {!isMobile ? (
        <div className="sub-container1 img-box1">
          <div className="twoMobileText">
            <p className="subHeading">Learn and Grow</p>
            <p className="para bullet-list-item">
              {" "}
              <span className="bullet-icon">•</span> Enhance your skills and
              broaden your horizons by attending a variety of activity classes.
              From yoga to wildlife photography, our app offers opportunities to
              enrich your knowledge and abilities.
            </p>
            <p className="subHeading">Expert Guided Expeditions</p>
            <p className="para bullet-list-item">
              {" "}
              <span className="bullet-icon">•</span>Elevate your adventures by
              hiring local guides. Choose from a curated list of experienced
              guid...
            </p>

            <div className="button">
              <CustomImageTextButton
                text="Learn More"
                imageSrc={require("../Images/Arrow 4.png")}
                height="auto"
                width="auto"
                // onClick={handleButtonClick}
              />
            </div>
          </div>
          <div className="twoMobile">
            <img
              className="mob3_img"
              src={require("../Images/mobile3.png")}
              alt=""
            />
            <img
              className="mob4_img"
              src={require("../Images/mobile4.png")}
              alt=""
            />
          </div>
        </div>
      ) : (
        <div className="  img-box1">
          <div className="twoMobile">
            <img
              className="mob3_img"
              src={require("../Images/mobile3.png")}
              alt=""
            />
            <img
              className="mob4_img"
              src={require("../Images/mobile4.png")}
              alt=""
            />
          </div>
          <div className="twoMobileText1">
            <p className="subHeading">Learn and Grow</p>
            <p className="para bullet-list-item">
              {" "}
              <span className="bullet-icon">•</span> Enhance your skills and
              broaden your horizons by attending a variety of activity classes.
              From yoga to wildlife photography, our app offers opportunities to
              enrich your knowledge and abilities.
            </p>
            <p className="subHeading">Expert Guided Expeditions</p>
            <p className="para bullet-list-item">
              {" "}
              <span className="bullet-icon">•</span>Elevate your adventures by
              hiring local guides. Choose from a curated list of experienced
              guid...
            </p>

            <div className="button">
              <CustomImageTextButton
                text="Learn More"
                imageSrc={require("../Images/Arrow 4.png")}
                height="auto"
                width="auto"
                // onClick={handleButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
