import "./App.css";

import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Home from "./Components/Home";

import GuidePage from "./Components/GuidePage";
import Navbar from "./Components/Navbar";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Footer from "./Components/Footer";
import TermsAndCondition from "./Components/TermsAndCondition";
import Community from "./pages/Community";
import Places from "./pages/Places";
import StayGuide from "./Components/stayGuide/StayGuide";
import RentalVheicle from "./Components/rentalVheicle/RentalVheicle";
import SelfCamping from "./Components/selfCamping/SelfCamping";
import Cafe from "./Components/cafe/Cafe";
import ShopGears from "./Components/shopGears/ShopGear";

function App() {
  const CommunityContainer = () => {
    const { postId } = useParams();

    return <Community postId={postId} />;
  };
  const PlaceContainer = () => {
    const { placeId } = useParams();

    return <Places placeId={placeId} />;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/guide" element={<GuidePage />} />
          <Route path="/stay_guide" element={<StayGuide />} />
          <Route path="/rental_vheicle" element={<RentalVheicle />} />
          <Route path="/self_camping" element={<SelfCamping />} />
          <Route path="/cafe" element={<Cafe />} />
          <Route path="/shop" element={<ShopGears />} />
          <Route path="/termsAndCondition" element={<TermsAndCondition />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/post/:postId" element={<CommunityContainer />} />
          <Route path="/place/:placeId" element={<PlaceContainer />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
