import React from "react";

const CustomTextImageButton = ({ text, imageSrc, onClick }) => {
  const buttonStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#31e3ae",
    borderRadius: "6px",
    padding: "4px 8px ",
    border: "none",
    color: "#ffffff",
    width: "auto",
    maxWidth: "300px",
    fontSize: "13px",
    fontWeight: "600",
  };

  const imageStyle = {
    width: "40%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "80%",
  };

  const textImageContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const tabletMediaQuery = "@media screen and (max-width: 1024px)";
  const mobileMediaQuery = "@media screen and (max-width: 768px)";

  return (
    <button style={buttonStyle} onClick={onClick}>
      <div style={textImageContainerStyle}>
        <span>{text}</span>
        <img src={imageSrc} alt="Button Icon" style={imageStyle} />
      </div>
      <style>
        {`
          ${tabletMediaQuery} {
            /* Add styles for tablets here */
            max-width: 250px;
          }

          ${mobileMediaQuery} {
            /* Add styles for mobile devices here */
            max-width: 200px;
          }
        `}
      </style>
    </button>
  );
};

export default CustomTextImageButton;
