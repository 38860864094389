import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./AutoComplete.css";
import "./AutoComplete.css";
import { width } from "@mui/system";
import { searchCitiesApi } from "../../services/apis/locationApi";

export default function CountryDropdown({
  cityValue,
  setCityId,
  onChange,
  style,
  error,
  cityName,
  setValidationErrors,
  validationErrors,
  states,
  setCountryName,
}) {
  const [dropdownData, setDropdownData] = useState([
    { country_id: 1, name: "india" },
  ]);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedValue, setSelectedValue] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3182ce" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="option-main">
        <div className="option-second">
          <img
            className="option-image"
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="option-lable">{label}</span>
      </div>
    </div>
  );

  const cityOptions = dropdownData?.map((city) => {
    return {
      value: city.country_id,
      label: city.name,
      image:
        city.image ||
        "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
    };
  });

  const handleCitySelection = (selectedOption) => {
    setSelectedValue(selectedOption.value);
    setCityId(selectedOption.value);
    setCountryName(selectedOption.label);
  };

  return (
    <div className="city-dropdon-wraper">
      <div className="city-dropdon-wraper">
        <div
          className={`mb-4 ${style === "stay" ? "mx-0 w-full" : "mx-6 w-2/3"}`}
        >
          <Select
            style={{ width: "100%" }}
            id="dropdown"
            name="dropdown"
            options={cityOptions}
            value={cityOptions.find((option) => option.value === selectedValue)}
            onInputChange={(inputValue) => {
              setSearchQuery(inputValue);
            }}
            onChange={handleCitySelection}
            isSearchable
            styles={customStyles}
            components={{ Option: CustomOption }}
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>
    </div>
  );
}
