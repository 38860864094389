import axios from "axios";
import { BASE_URL } from "../../constants/constant";
export const socialMediaPostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(
    `${BASE_URL}/bookings/socialmedia/`,
    data,
    config
  );

  return response;
};
