import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Typography,
  FormControl,
  Select,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { countries, cities, famousPlaces } from "./LocationData";
import ApiComponent from "./ApiComponent";
import { useNavigate } from "react-router-dom";
import DialogBox from "./DialogBox";
import CityDropdown from "./autocomplete/CityDropdown";
import StateDropdown from "./autocomplete/StateDropdown";
import CountryDropdown from "./autocomplete/CountryDropdown";

const FormPage = () => {
  const id = uuidv4();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    titles: "",
    name: "",
    aadhar_number: "",
    first_name: "",
    last_name: "",
    bio: "",
    dob: "",
    gender: "",
    country: "",
    phone: "",
    whatsapp_number: "",
    email: "",
    zip_code: "",
    currency: "",
    languages: [],
    activities: [],
    hobbies: [],
    spot_city: "",
    places: "",
    year_of_experience: "",
    price_per_day: "",
  });
  const [dob, setDOB] = useState("");
  const [image, setImage] = useState(null);
  const [adharCard, setAdharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [otherID, setOtherID] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [states, setStates] = useState([]);
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    if (countryName) {
      const country = countries.find((country) => country.name === countryName);

      const stateArray = countries[0]?.states || [];
      const stateObjects = stateArray.map((state, index) => ({
        state_id: index + 1,
        name: state,
      }));
      setStates(stateObjects);
    }
  }, [countryName, countries]);

  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // If the input is titles, first_name, or last_name, update the name field
    if (name === "titles" || name === "first_name" || name === "last_name") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        name: `${prevFormData.titles} ${prevFormData.first_name} ${prevFormData.last_name}`,
      }));
    } else {
      if (name !== "city" && name !== "state") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    }

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    setSelectedState("");
    setFormData({
      ...formData,
      country: selectedCountry,
      // state: "",
      // city: "",
      spot_city: "",
      places: "",
    });
  };

  useEffect(() => {
    if (countryName) {
      setSelectedCountry(countryName);
      setSelectedState("");
      setFormData({
        ...formData,
        country: countryName,

        spot_city: "",
        places: "",
      });
    }
  }, [countryName]);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setFormData({
      ...formData,
      // state: selectedState,
      // city: "",
      spot_city: "",
      places: "",
    });
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setFormData({
      ...formData,
      city: selectedCity,
      spot_city: selectedCity,
      places: "",
      dob: dob,
    });
  };

  const handleGuideSpotCityChange = (event) => {
    const selectedSpotCity = event.target.value;
    setFormData({
      ...formData,
      spot_city: selectedSpotCity,
      places: "",
    });
  };
  const handleSpotPlacesChange = (event) => {
    const selectedSpotPlace = event.target.value;
    setFormData({
      ...formData,
      places: selectedSpotPlace,
    });
  };

  const loadFamousPlaces = () => {
    const selectedCity = formData.city;
    if (selectedCity) {
      setFormData({
        ...formData,
        places: famousPlaces[selectedCity] || "",
      });
    }
  };
  useEffect(() => {
    loadFamousPlaces();
  }, [formData.city]);

  // const validateEmail = (email) => {
  //   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   return emailRegex.test(email);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (cityId === "") {
      setCityError("This field is required.");
    } else {
      setCityError("");
    }

    if (stateId === "") {
      setStateError("This field is required.");
    } else {
      setStateError("");
    }

    // if (!validateEmail(formData.email)) {
    //   errors.email = "Invalid email format";
    // }
    // const phoneRegex = /^[0-9]{10}$/; // Validate for 10 digits only

    // if (!phoneRegex.test(formData.phone)) {
    //   errors.phone = "Invalid phone number format. It should be 10 digits.";
    // }
    // function validateAadhar(aadhar) {
    //   // Aadhar number should be 12 digits
    //   const aadharRegex = /^\d{12}$/;
    //   return aadharRegex.test(aadhar);
    // }
    // if (!validateAadhar(formData.aadhar_number)) {
    //   errors.aadhar_number = "Invalid Aadhar format";
    // }
    // // Function to validate PAN number
    // function validatePAN(pan) {
    //   // PAN number should be in the format ABCDE1234F
    //   const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    //   return panRegex.test(pan);
    // }
    // if (!validatePAN(formData.panCard)) {
    //   errors.panCard = "Invalid Aadhar format";
    // }
    // if (
    //   formData.whatsapp_number &&
    //   !phoneRegex.test(formData.whatsapp_number)
    // ) {
    //   errors.whatsapp_number =
    //     "Invalid WhatsApp number format. It should be 10 digits.";
    // }

    if (!formData.gender) {
      errors.gender = "Gender is required.";
    }
    if (!image) {
      errors.image = "Image is required.";
    }

    // if (!dob) {
    //   errors.dob = "DOB is required.";
    // }

    // if (!formData.aadhar_number) {
    //   errors.aadhar_number = "Adhaar Number is required.";
    // }

    if (!formData.hobbies || formData.hobbies.length === 0) {
      errors.hobbies = "At least one hobby is required.";
    }

    if (!formData.activities || formData.activities.length === 0) {
      errors.activities = "At least one activity is required.";
    }

    if (!formData.languages || formData.languages.length === 0) {
      errors.languages = "At least one language is required.";
    }
    for (const key in formData) {
      if (
        key !== "whatsapp_number" &&
        key !== "email" &&
        key !== "phone" &&
        key !== "dob"
      ) {
        if (!formData[key]) {
          errors[key] = "This field is required.";
        }
      }
    }
    setFormErrors(errors);

    // If there are validation errors, don't submit the request
    if (Object.keys(errors).length > 0) {
      return;
    }

    // Prepare form data
    const formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("aadhar_number", formData.aadhar_number);

    formdata.append("bio", formData.bio);
    formdata.append("dob", dob);
    formdata.append("gender", formData.gender);
    formdata.append("country", formData.country);
    formdata.append("state", stateId);
    formdata.append("city", cityId);
    formdata.append("phone", formData.phone);
    formdata.append("whatsapp_number", formData.whatsapp_number);
    formdata.append("email", formData.email);
    formdata.append("zip_code", formData.zip_code);
    formdata.append("currency", formData.currency);
    formdata.append("languages", formData.languages.join(",")); // Assuming languages is an array
    formdata.append("activities", formData.activities.join(",")); // Assuming activities is an array
    formdata.append("hobbies", formData.hobbies.join(",")); // Assuming hobbies is an array
    formdata.append("spot_city", formData.spot_city);
    formdata.append("places", formData.places);
    formdata.append("year_of_experience", formData.year_of_experience);
    formdata.append("price_per_day", formData.price_per_day);

    // Append files
    formdata.append("image", image);
    formdata.append("adharCard", adharCard);
    formdata.append("panCard", panCard);
    formdata.append("otherID", otherID);

    // Make the API call
    try {
      const response = await fetch(
        "https://orca-app-pgxkr.ondigitalocean.app/website/guide-enquiry/",
        {
          method: "POST",

          body: formdata,
          redirect: "follow",
        }
      );
      if (!response.ok) {
        const responseBody = await response.text();
        handleOpenDialog(
          "Failure",
          "Failed to submit the form data. Please try again."
        );
        console.error(
          "HTTP error! Status:",
          response.status,
          "Response:",
          responseBody
        );
        // Handle the error response
      } else {
        // Handle the success response
        await handleOpenDialog("Success", "Form data submitted successfully.");

        await new Promise((resolve) => setTimeout(resolve, 2000));

        window.scrollTo(0, 0);

        navigate("/");
      }
    } catch (error) {
      console.error("API Error:", error);
      // Handle other errors (e.g., network error)
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container maxWidth="lg" spacing={2}>
          <Grid item xs={5} sm={2}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Title
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              <Select
                name="titles"
                value={formData.titles}
                placeholder="Title"
                onChange={handleInputChange}
              >
                <MenuItem value="Mr.">Mr.</MenuItem>
                <MenuItem value="Mrs.">Mrs.</MenuItem>
                <MenuItem value="Miss">Miss</MenuItem>
              </Select>
            </FormControl>
            {formErrors.titles && (
              <Typography variant="body2" color="error">
                {formErrors.titles}
              </Typography>
            )}
          </Grid>
          <Grid item xs={7} sm={5}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              First Name
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              placeholder="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
            />
            {formErrors.first_name && (
              <Typography variant="body2" color="error">
                {formErrors.first_name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Last Name
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              placeholder="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
            />
            {formErrors.last_name && (
              <Typography variant="body2" color="error">
                {formErrors.last_name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Bio
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              placeholder="Bio"
              name="bio"
              multiline
              rows={2}
              value={formData.bio}
              onChange={handleInputChange}
            />
            {formErrors.bio && (
              <Typography variant="body2" color="error">
                {formErrors.bio}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Image
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              type="file"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {formErrors.image && (
              <Typography variant="body2" color="error">
                {formErrors.image}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Date Of Birth
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              type="date"
              name="dob"
              value={dob}
              onChange={(e) => setDOB(e.target.value)}
            />
            {formErrors.dob && (
              <Typography variant="body2" color="error">
                {formErrors.dob}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Gender
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <RadioGroup
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
                sx={{ marginLeft: 0 }}
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
                sx={{ marginLeft: 0 }}
              />
              <FormControlLabel
                value="others"
                control={<Radio />}
                label="Others"
                sx={{ marginLeft: 0 }}
              />
            </RadioGroup>
            {formErrors.gender && (
              <Typography variant="body2" color="error">
                {formErrors.gender}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Country
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              {/* <Select
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
              >
                {countries.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.country && (
                <Typography variant="body2" color="error">
                  {formErrors.country}
                </Typography>
              )} */}
              <CountryDropdown
                cityValue={countryName}
                setCityId={setCountryId}
                style={"stay"}
                cityName={countryId}
                setCountryName={setCountryName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              State
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              {/* <Select
                name="state"
                value={selectedState}
                onChange={handleStateChange}
              >
                {selectedCountry &&
                  countries
                    .find((country) => country.name === selectedCountry)
                    .states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
              </Select>
              {formErrors.state && (
                <Typography variant="body2" color="error">
                  {formErrors.state}
                </Typography>
              )} */}
              <StateDropdown
                cityValue={stateName}
                setCityId={setStateId}
                style={"stay"}
                cityName={stateId}
                states={states}
                error={stateError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              City
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            {/* <TextField
              fullWidth
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleCityChange}
            />
            {formErrors.city && (
              <Typography variant="body2" color="error">
                {formErrors.city}
              </Typography>
            )} */}
            <CityDropdown
              cityValue={cityName}
              setCityId={setCityId}
              style={"stay"}
              cityName={cityId}
              error={cityError}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Number
              {/* <span style={{ color: "red" }}>*</span> */}
            </FormLabel>
            <TextField
              fullWidth
              name="phone"
              placeholder="+91"
              value={formData.phone ? `${formData.phone}` : ""}
              onChange={handleInputChange}
            />
            {formErrors.phone && (
              <Typography variant="body2" color="error">
                {formErrors.phone}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Whatsapp Number
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              name="whatsapp_number"
              placeholder="+91"
              value={
                formData.whatsapp_number ? `${formData.whatsapp_number}` : ""
              }
              onChange={handleInputChange}
            />
            {formErrors.whatsapp_number && (
              <Typography variant="body2" color="error">
                {formErrors.whatsapp_number}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Zip Code
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              name="zip_code"
              placeholder="Zip Code"
              value={formData.zip_code}
              onChange={handleInputChange}
            />
            {formErrors.zip_code && (
              <Typography variant="body2" color="error">
                {formErrors.zip_code}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Currency
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              <Select
                fullWidth
                name="currency"
                placeholder="Currency"
                value={formData.currency}
                onChange={handleInputChange}
              >
                <MenuItem value="Rupee">Rupee</MenuItem>
                <MenuItem value="Dollar">Dollar</MenuItem>
              </Select>
            </FormControl>
            {formErrors.currency && (
              <Typography variant="body2" color="error">
                {formErrors.currency}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Email
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              placeholder="User@gmail.com"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {formErrors.email && (
              <Typography variant="body2" color="error">
                {formErrors.email}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Languages
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              <Select
                name="languages"
                placeholder="languages"
                value={formData.languages}
                onChange={handleInputChange}
                multiple
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Hindi">Hindi</MenuItem>
              </Select>
            </FormControl>
            {formErrors.languages && (
              <Typography variant="body2" color="error">
                {formErrors.languages}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Activities
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              <Select
                name="activities"
                value={formData.activities}
                onChange={handleInputChange}
                multiple
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="Trekking">Trekking</MenuItem>
                <MenuItem value="Beach Activities">Beach Activities</MenuItem>
                <MenuItem value="Wildlife Safari">Wildlife Safari</MenuItem>
                <MenuItem value="Backwater Cruise">Backwater Cruise</MenuItem>
                <MenuItem value="Yoga and Meditation">
                  Yoga and Meditation
                </MenuItem>
                <MenuItem value="Cultural Exploration">
                  Cultural Exploration
                </MenuItem>
                <MenuItem value="Desert Safari">Desert Safari</MenuItem>
                <MenuItem value="Heritage Walk">Heritage Walk</MenuItem>
                <MenuItem value="Tea Plantation Tour">
                  Tea Plantation Tour
                </MenuItem>
                <MenuItem value="River Rafting">River Rafting</MenuItem>
              </Select>
            </FormControl>
            {formErrors.activities && (
              <Typography variant="body2" color="error">
                {formErrors.activities}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Hobbies
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              <Select
                name="hobbies"
                value={formData.hobbies}
                onChange={handleInputChange}
                multiple
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="Reading">Reading</MenuItem>
                <MenuItem value="Painting">Painting</MenuItem>
                <MenuItem value="Dancing">Dancing</MenuItem>
                <MenuItem value="Photography">Photography</MenuItem>
                <MenuItem value="Cooking">Cooking</MenuItem>
                <MenuItem value="Gardening">Gardening</MenuItem>
                <MenuItem value="Playing a Musical Instrument">
                  Playing a Musical Instrument
                </MenuItem>
                <MenuItem value="Traveling">Traveling</MenuItem>
                <MenuItem value="Writing">Writing</MenuItem>
              </Select>
            </FormControl>
            {formErrors.hobbies && (
              <Typography variant="body2" color="error">
                {formErrors.hobbies}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Spot City
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              name="spot_city"
              value={formData.spot_city}
              onChange={handleGuideSpotCityChange}
            />
            {formErrors.spot_city && (
              <Typography variant="body2" color="error">
                {formErrors.spot_city}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Famous Places
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              name="places"
              value={formData.places}
              onChange={handleSpotPlacesChange}
            />
            {formErrors.places && (
              <Typography variant="body2" color="error">
                {formErrors.places}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Years of Experience <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <FormControl fullWidth>
              <Select
                type="number"
                name="year_of_experience"
                value={formData.year_of_experience}
                onChange={handleInputChange}
                placeholder="Select year"
              >
                <MenuItem value={"0-1"}>0-1 years</MenuItem>
                <MenuItem value={"1-2"}>1-2 years</MenuItem>
                <MenuItem value={"2-3"}>2-3 years</MenuItem>
                <MenuItem value={"3-4"}>3-4 years</MenuItem>
                <MenuItem value={"4-5"}>4-5 years</MenuItem>
                <MenuItem value={"5-6"}>5-6 years</MenuItem>
                <MenuItem value={"6-7"}>6-7 years</MenuItem>
                <MenuItem value={"7+"}>7+ years</MenuItem>
              </Select>
            </FormControl>
            {formErrors.year_of_experience && (
              <Typography variant="body2" color="error">
                {formErrors.year_of_experience}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Price Per Day
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <TextField
              fullWidth
              type="number"
              placeholder="Price Per Day"
              name="price_per_day"
              value={formData.price_per_day}
              onChange={handleInputChange}
            />
            {formErrors.price_per_day && (
              <Typography variant="body2" color="error">
                {formErrors.price_per_day}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Aadhar Number
              {/* <span style={{ color: "red" }}>*</span> */}
            </FormLabel>
            <TextField
              fullWidth
              type="text" // Change the type to "text" to accept Aadhar number
              name="aadhar_number"
              placeholder="Aadhar Number"
              value={formData.aadhar_number}
              onChange={handleInputChange}
            />
            {formErrors.aadhar_number && (
              <Typography variant="body2" color="error">
                {formErrors.aadhar_number}
              </Typography>
            )}
          </Grid>
          {/* <Grid container spacing={2}> */}
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              PAN Card Number
            </FormLabel>
            <TextField
              fullWidth
              type="text"
              placeholder="Pan Card Number"
              name="pan_id"
              value={formData.pan_id}
              onChange={handleInputChange}
            // onBlur={handlePanIdBlur}
            />
            {formErrors.pan_id && (
              <Typography variant="body2" color="error">
                {formErrors.pan_id}
              </Typography>
            )}
          </Grid>
          {/* Other form fields go here */}
          {/* </Grid> */}
          <Grid item xs={12} sm={6} md={12}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Other Document
            </FormLabel>
            <TextField
              fullWidth
              type="file"
              name="other_id"
              onChange={(e) => setOtherID(e.target.files[0])}
            />
            {formErrors.other_id && (
              <Typography variant="body2" color="error">
                {formErrors.other_id}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Submitted By
            </FormLabel>
            <TextField
              fullWidth
              name="submitted_by"
              placeholder="Name"
              value={formData.submitted_by}
              onChange={handleInputChange}
            />
            {formErrors.submitted_by && (
              <Typography variant="body2" color="error">
                {formErrors.submitted_by}
              </Typography>
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <FormLabel
              sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
            >
              Submitted Phone
            </FormLabel>
            <TextField
              fullWidth
              name="submitted_phone"
              placeholder="+91"
              value={formData.submitted_phone}
              onChange={handleInputChange}
            />
            {formErrors.submitted_phone && (
              <Typography variant="body2" color="error">
                {formErrors.submitted_phone}
              </Typography>
            )}
          </Grid> */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                backgroundColor: "#31E3AE",
                marginBottom: "22px",
                padding: "4px 20px ",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <DialogBox
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogContent.title}
        content={dialogContent.message}
      />
    </div>
  );
};
export default FormPage;
