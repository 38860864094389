// import React from "react";
// import "../Style/BarCode.css";

// export default function BarCode() {
//   return (
//     <>
//       {/* dextop view */}
//       <div className="airoplan">
//         <img src={require("../Images/airoplan.png")} alt="" />
//       </div>
//       <div className="varcode_main_div">
//         <div className="Varcode_inner_div1">
//           <img
//             className="varcode_man_image"
//             src={require("../Images/varcode_man.png")}
//             alt="#"
//           />
//           <div className="varCode_up">
//             <h6>We're Currently Hiring Guides</h6>
//             <p>
//               Lorem Ipsum is simply dummy text of the printing and typesetting
//               industry. Lorem Ipsum has been the industry's standard dummy text
//               Lorem Ipsum is simply dummy text of the printing and typesetting
//               industry. Lorem Ipsum has been the industry's stand
//             </p>
//             <button>Register as Guide</button>
//             <p>Register as guide and fill the google form</p>
//           </div>
//         </div>
//         <div className="Varcode_inner_div2">
//           <div className="varCode_left_2">
//             <h6>"GoWild:Discover, Explore,GoWild!"</h6>
//             <p>Don't Wait,Downloads the app,plan your trip....!</p>
//             <span>
//               <img src={require("../Images/google.png")} alt="" />
//               <img src={require("../Images/appStore.png")} alt="" />
//             </span>
//           </div>
//           <img
//             className="varcode_image"
//             src={require("../Images/varcode.png")}
//             alt="#"
//           />
//         </div>
//       </div>
//       {/* mobile view */}

//       <div className="varcode_mobile_main">
//         <div className="varcode_mobile_inner">
//           <h6>We're Currently Hiring Guides</h6>
//           <p>
//             Are you passionate about the outdoors, an excellent storyteller, and
//             known for your humility and kindness with people? If so, we're
//             looking for individuals like you to join our team of guides. Share
//             your expertise, inspire others, and make a difference in the world
//             of outdoor adventures. Register yourself today and embark on a
//             rewarding journey with us."
//           </p>
//           <p>Register as guide and fill the google form</p>

//           <button>Register as Guide</button>
//         </div>
//       </div>
//     </>
//   );
// }
import React, { useEffect, useState } from "react";
import "../Style/BarCode.css";
import { Link } from "react-router-dom";

export default function BarCode() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 928);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 928);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleAppStoreClick = () => {
    // window.location.href = "https://apps.apple.com/in/app/gowild-destiny/id6477920132";
    window.open("https://apps.apple.com/in/app/gowild-destiny/id6477920132", "_blank");
  };

  const handleplayStoreClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.destiny.gowild", "_blank");
  };

  return (
    <div>
      <div className="airoplan">
        <img src={require("../Images/airoplan.png")} alt="" />
      </div>
      {!isMobile ? (
        <div>
          <div className="barcode_container">
            <div className="test">
              <img
                className="barcode_man"
                src={require("../Images/varcode_man.png")}
                alt="#"
              />
              <div className="were-hiring">
                <p className="subHeading">We're Currently Hiring Guides</p>
                <p className="hiringPara">
                  Are you passionate about the outdoors, an excellent
                  storyteller, and known for your humility and kindness with
                  people? If so, we're looking for individuals like you to join
                  our team of guides. Share your expertise, inspire others, and
                  make a difference in the world of outdoor adventures. Register
                  yourself today and embark on a rewarding journey with us."
                </p>
                <Link to="/guide">
                  <button className="register-btn">Register as Guide</button>
                </Link>

                <p className="hiringPara">
                  Register as guide and fill the google form
                </p>
              </div>
            </div>
            <div className="test2">
              <div className="barCode_left_2">
                <p className="subHeading">"GoWild:Discover, Explore,GoWild!"</p>
                <p className="hiringPara2">
                  Don't Wait,Downloads the app,plan your trip....!
                </p>
                <span className="test3">
                  <img
                    src={require("../Images/google.png")}
                    alt=""
                    className="btn-img"
                    onClick={handleplayStoreClick}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={require("../Images/appStore.png")}
                    alt=""
                    className="btn-img"
                    onClick={handleAppStoreClick}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
            </div>
            <div className="barcode_contianer">
              <img
                className="barcode_image"
                src={require("../Images/varcode.png")}
                alt="#"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="barcode_container">
          <div className="test">
            <div className="left-barcode">
              <div>
                <img
                  className="barcode_man"
                  src={require("../Images/varcode_man.png")}
                  alt="#"
                />
              </div>
              <div className="div2-barcode">
                <img
                  className="pink-camera"
                  src={require("../Images/front view of pink camera.png")}
                  alt="#"
                />
                <img
                  className="frame"
                  src={require("../Images/Frame.png")}
                  alt="#"
                />
              </div>
              <div className="barcodeimg">
                <img
                  className="barcode_image"
                  src={require("../Images/women-walking-see-her-map-jungle-board-style-backgro-women-walking-see-her-map-jungle-board-style-122793668-removebg-preview 3.png")}
                  alt="#"
                />
                <img
                  className="barcode"
                  src={require("../Images/image 59.png")}
                  alt="#"
                />
              </div>
            </div>
            <div className="right-barcode">
              {" "}
              <div className="were-hiring">
                <p className="subHeading">We're Currently Hiring Guides</p>
                <p className="hiringPara">
                  Are you passionate about the outdoors, an excellent
                  storyteller, and known for your humility and kindness with
                  people? If so, we're looking for individuals like you to join
                  our team of guides. Share your expertise, inspire others, and
                  make a difference in the world of outdoor adventures. Register
                  yourself today and embark on a rewarding journey with us."
                </p>
                <p className="hiringPara">
                  Register as guide and fill the google form
                </p>
                {/* <Link to="/guide"> */}
                <button className="register-btn">Register as Guide</button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
