import React, { useEffect } from "react";
import "../Style/TermsAndCondition.css";
export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-image">
      <div className="image-container">
        <img
          src={require("../Images/headerimg.png")}
          alt=""
          style={{ width: "100%", height: "auto" }}
        />
        <div className="privacy-policy-heading">Privacy Policy</div>
      </div>

      <div className="container2">
        <img src={require("../Images/balun.png")} alt="" className="balun1" />
        <img src={require("../Images/balun.png")} alt="" className="balun2" />
        <img src={require("../Images/balun.png")} alt="" className="balun3" />
        <div className="side-roll">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="205"
            viewBox="0 0 144 365"
            fill="none"
          >
            <path
              d="M-43.927 46.9223C-17.4151 56.93 14.4664 67.0466 43.0777 62.4174C58.5535 59.9259 74.6707 42.9016 77.3852 27.1797C81.3841 3.88464 51.9582 -9.43568 36.8911 9.7842C26.9871 22.4218 23.6799 39.2772 24.8187 55.2977C27.7998 97.3601 52.2864 139.748 77.4736 172.445C128.222 238.312 177.715 326.72 108.716 364.341"
              stroke="#1D1D1D"
              stroke-width="0.9767"
              stroke-miterlimit="10"
              stroke-dasharray="6.01 6.01"
            />
          </svg>
        </div>
        <p className="para">
          "A Privacy Policy is a crucial document that outlines how you collect,
          use, store, and protect user data in your app. Here's a general
          structure for a Privacy Policy, along with what to include in each
          section:
        </p>
        <div></div>
        <div className="lilpadding">
          <p className="subHeading1">1. Introduction:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Start with an introduction
            that explains the purpose of the Privacy Policy and its importance.
            Mention your app's name and a brief description of what it does.
          </p>
          <p className="subHeading1">2. Information Collected:</p>
          <p className="para ">
            {" "}
            <span className="bullet-icon">•</span> Specify the types of
            information you collect from users. This can include:{" "}
            <div className="subParaHeading">
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Personal information
                (name, email, etc.)
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Location data
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Device information (device
                type, OS version, etc.)
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Usage data (app activity,
                interactions, etc.)
              </p>
            </div>
          </p>
          <p className="subHeading1">3. How Information is Collected:</p>
          <p className="para bullet-list-item">
            <span className="bullet-icon">•</span> Explain the methods you use
            to collect user data, such as through app registration, user
            interactions, cookies, or third-party services.
          </p>
          <p className="subHeading1">4. Use of Information:</p>
          <p className="para ">
            {" "}
            <span className="bullet-icon">•</span> Describe how you intend to
            use the collected data. For example, you might use it to:{" "}
            <div className="subParaHeading">
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Improve app functionality
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Personalize user
                experiences
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Send marketing or
                app-related communications
              </p>
            </div>
          </p>

          <p className="subHeading1">5. Data Sharing:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Clarify if you share user
            data with third parties (e.g., analytics providers, advertisers) and
            for what purposes. Specify if data is anonymized or not.
          </p>
          <p className="subHeading1">6. Data Security:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span>Explain the security measures
            in place to protect user data from unauthorized access or breaches.
          </p>
          <p className="subHeading1">7. User Control:</p>
          <p className="para ">
            {" "}
            <span className="bullet-icon">•</span> Inform users about their
            rights and how they can:
            <div className="subParaHeading">
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Access and review their
                data
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Request data deletion
              </p>
              <p className=" bullet-list-item">
                <span className="bullet-icon">•</span>Opt-out of data collection
                or marketing communications
              </p>
            </div>
          </p>
          <p className="subHeading1">8. Cookies and Tracking Technologies:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> If you use cookies or
            tracking technologies, explain their purpose and how users can
            manage or disable them.
          </p>
          <p className="subHeading1">9. Third-Party Links and Services:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span>Mention if your app contains
            links to third-party websites or services and state that you are not
            responsible for their privacy practices.
          </p>
          <p className="subHeading1">10. Children's Privacy: </p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> If your app is not intended
            for children under 13 (or the applicable age in your jurisdiction),
            state that you do not knowingly collect data from children.
          </p>
          <p className="subHeading1">11. Changes to Privacy Policy: </p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span>  Explain that you have the
            right to modify the Privacy Policy and how users will be informed of
            changes.
          </p>
          <p className="subHeading1">12. Contact Information: -</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Provide contact information
            for users to reach out for privacy-related inquiries or requests.
          </p>
          <p className="subHeading1">13. Compliance with Laws: -</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> State your commitment to
            complying with applicable data protection laws, such as GDPR or
            CCPA, depending on your user base.
          </p>
          <p className="subHeading1">14. Effective Date: -</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span>  Include the date when the
            Privacy Policy was last updated or became effective.
          </p>
        </div>
      </div>

      <div className="airoplan">
        <img src={require("../Images/airoplan.png")} alt="" />
      </div>
    </div>
  );
}
