import React, { useEffect } from "react";
import "../Style/TermsAndCondition.css";
export default function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="headerImg">
        <img
          src={require("../Images/Group 1686553715.png")}
          alt=""
          className="header-image"
        />
        <div className=" t-n-s-header">Term And Services</div>
      </div>

      <div className="container1">
        <img src={require("../Images/balun.png")} alt="" className="balun1" />
        <img src={require("../Images/balun.png")} alt="" className="balun2" />
        <img src={require("../Images/balun.png")} alt="" className="balun3" />
        <div className="side-roll">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="205"
            viewBox="0 0 144 365"
            fill="none"
          >
            <path
              d="M-43.927 46.9223C-17.4151 56.93 14.4664 67.0466 43.0777 62.4174C58.5535 59.9259 74.6707 42.9016 77.3852 27.1797C81.3841 3.88464 51.9582 -9.43568 36.8911 9.7842C26.9871 22.4218 23.6799 39.2772 24.8187 55.2977C27.7998 97.3601 52.2864 139.748 77.4736 172.445C128.222 238.312 177.715 326.72 108.716 364.341"
              stroke="#1D1D1D"
              stroke-width="0.9767"
              stroke-miterlimit="10"
              stroke-dasharray="6.01 6.01"
            />
          </svg>
        </div>
        <p className="para">
          The "Terms of Service" agreement, also known as "Terms and
          Conditions," outlines the rules and guidelines that users must follow
          when using your app or website. It helps protect your rights and the
          rights of your users. Here are the key sections and what to include in
          a Terms of Service agreement for your app:
        </p>
        <div></div>
        <div className="lilpadding">
          <p className="subHeading1">1. Introduction:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Begin with a clear and
            concise introduction that states that these terms govern the use of
            your app. Mention your app's name and a brief description of what it
            does.
          </p>
          <p className="subHeading1">2. Acceptance of Terms:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Explain that by using the
            app, users are agreeing to comply with the terms and conditions
            outlined in the agreement.
          </p>
          <p className="subHeading1">3. User Registration:</p>
          <p className="para bullet-list-item">
            <span className="bullet-icon">•</span> If your app requires user
            registration, specify the requirements and responsibilities related
            to creating an account.
          </p>
          <p className="subHeading1">4. User Conduct:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Outline expected user
            behavior and what actions are prohibited, such as harassment,
            spamming, or violating intellectual property rights.
          </p>
          <p className="subHeading1">5. Privacy Policy:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Mention that users should
            also review your app's Privacy Policy to understand how their data
            is collected, used, and protected.
          </p>
          <p className="subHeading1">6. Intellectual Property Rights:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span>Clarify who owns the content
            and intellectual property associated with the app. Explain that
            users do not have the right to copy, reproduce, or distribute the
            app's content.
          </p>
          <p className="subHeading1">7. Content Upload and Sharing:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span>If users can upload content
            (e.g., photos, videos, comments), specify their rights and
            responsibilities. Include a clause stating that users are
            responsible for the content they upload.
          </p>
          <p className="subHeading1">8. Termination of Accounts:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Explain the circumstances
            under which you can terminate user accounts, such as for violating
            the terms or for security reasons.
          </p>
          <p className="subHeading1">9. Dispute Resolution:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Include a section on how
            disputes will be resolved, whether through arbitration, mediation,
            or litigation.
          </p>
          <p className="subHeading1">10. Limitation of Liability:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Limit your liability in case
            of any issues arising from app use, such as data loss or service
            interruptions.
          </p>
          <p className="subHeading1">11. Changes to Terms:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Specify that you have the
            right to modify the terms at any time and how users will be notified
            of changes.
          </p>
          <p className="subHeading1">12. Governing Law:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Indicate the jurisdiction and
            laws that will govern the agreement.
          </p>
          <p className="subHeading1">13. Contact Information:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Provide a contact email or
            address for users to reach out for support or questions regarding
            the terms.
          </p>
          <p className="subHeading1">14. Effective Date:</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Include the date when the
            terms were last updated or became effective.
          </p>
        </div>
      </div>

      <div className="airoplan">
        <img src={require("../Images/airoplan.png")} alt="" />
      </div>
    </div>
  );
}
