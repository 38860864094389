import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import SelfCampingForm from "./SelfCampingForm";

const SelfCamping = () => {
  return (
    <>
      <Box
        sx={{
          bgcolor: " #31E3AE;",
          pt: 3,
          pb: 3,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            component="h2"
            variant="h4"
            align="left"
            color="white"
            fontWeight={800}
            gutterBottom
          >
            Partner As Self Camping
          </Typography>
        </Container>
      </Box>
      <Box
        sx={{
          bgcolor: "white",
          pt: 3,
          pb: 3,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h6"
            align="left"
            color="text.secondary"
            fontWeight={400}
            paragraph
          >
            "A Privacy Policy is a crucial document that outlines how you
            collect, use, store, and protect user data in your app. Here's a
            general structure for a Privacy Policy, along with what to include
            in each section Start with an introduction that explains the purpose
            of the Privacy Policy and its importance. Mention your app's name
            and a brief description of what it does. A Privacy Policy is a
            crucial document that outlines how you collect, use, store, and
            protect user data in your app. Here's a general structure for a
            Privacy Policy, along with what to include in each section Start
            with an introduction that explains the purpose of the Privacy Policy
            and its importance. Mention your app's name and a brief description
            of what it does.
          </Typography>
        </Container>
      </Box>
      <SelfCampingForm />
    </>
  );
};

export default SelfCamping;
