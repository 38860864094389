import {
  Button,
  Chip,
  Container,
  FormControl,
  FormLabel,
  Grid,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MdOutlineGpsFixed } from "react-icons/md";
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { countries } from "../LocationData";
import "../../Style/StayGuide.css";

import { LuImagePlus } from "react-icons/lu";

import { RiDeleteBin7Line } from "react-icons/ri";
import { CiCirclePlus } from "react-icons/ci";
import PopOver from "../popover/Popover";
import ConfirmationDialog from "../confirmationDialog/ConfirmationDialog";
import { imageDeleteApi, imagePostApi } from "../../services/apis/imagesApi";
import { getCitiesApi } from "../../services/apis/locationApi";
import {
  bookingShopgearPostApi,
  bookingShopgearFacitiliesApi,
  bookingShopgearLengthApi,
} from "../../services/apis/enquairyApi";
import { socialMediaPostApi } from "../../services/apis/socialMediaApi";
import CityDropdown from "../autocomplete/CityDropdown";
import DialogBox from "../DialogBox";

const ShopGearForm = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });

  const [formData, setFormData] = useState({
    shopName: "",
    phone: "",
    alternate_number: "",
    email: "",
    address: "",
    Latitude: "",
    Longitude: "",
    facebook: "",
    youtube: "",
    twitter: "",
    instagram: "",
    Description: "",
  });
  const [image, setImage] = useState(null);
  const [photos, setPhotos] = useState([
    { id: uuidv4(), file: null, url: null },
  ]);
  const [photoIds, setPhotoIds] = useState([]);
  const [photosError, setPhotosError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProductIndex, setCurrentProductIndex] = useState(null);
  const [citiesData, setCitiesData] = useState([]);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [facilitiesIds, setFacilitiesIds] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [lengthIds, setLengthIds] = useState([]);
  const [cityId, setCityId] = useState("");
  const [cityError, setCityError] = useState("");

  const [cityName, setCityName] = useState("");

  const [error, setError] = useState(null);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });
  const [product, setProduct] = useState([
    {
      product_id: "",
      brand: "",
      product_name: "",
      product_description: "",
      product_price: "",
      Price: "",
      product_customization: "",
      Rental_Purchase: "",
      facilities: "",
      // short_title: "",
      // short_description: "",
      photos: [{ id: uuidv4(), file: null, url: null }],
      photoIds: [],
    },
  ]);

  useEffect(() => {
    if (location.latitude && location.longitude) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Latitude: location.latitude,
        Longitude: location.longitude,
      }));
    }
  }, [location, setFormData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCitiesApi(currentPage);
        if (response.status === 200) {
          setCitiesData(response?.data?.results);
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog("Opps, Something went wrong !");
      }
      try {
        const response = await bookingShopgearFacitiliesApi(currentPage);
        if (response.status === 200) {
          setFacilitiesData(response?.data?.results);
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog("Opps, Something went wrong !");
      }
      try {
        const response = await bookingShopgearLengthApi(currentPage);
        if (response.status === 200) {
          // setFacilitiesData(response?.data?.results);
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog("Opps, Something went wrong !");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFacilitiesIds(facilitiesData.map((chip) => chip.id));
  }, [facilitiesData]);

  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "titles" || name === "first_name" || name === "last_name") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        name: `${prevFormData.titles} ${prevFormData.first_name} ${prevFormData.last_name}`,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setFormErrors({
      ...formErrors,
      [name]: "",
    });

    const urlRegex =
      /^(http(s)?:\/\/)?(www\.)?(instagram|facebook|twitter|youtube)\.com\/[a-zA-Z0-9_]+$/;

    if (["instagram", "facebook", "twitter", "youtube"].includes(name)) {
      if (value && !urlRegex.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `Invalid ${name} URL format`,
        }));
      }
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    setFormData({
      ...formData,
      state: selectedState,
      city: "",
      spot_city: "",
      places: "",
    });
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   return emailRegex.test(email);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (cityId === "") {
      setCityError("This field is required.");
    } else {
      setCityError("");
    }

    // if (!validateEmail(formData.email)) {
    //   errors.email = "Invalid email format";
    // }
    // const phoneRegex = /^[0-9]{10}$/; // Validate for 10 digits only

    // if (!phoneRegex.test(formData.phone)) {
    //   errors.phone = "Invalid phone number format. It should be 10 digits.";
    // }

    // if (
    //   formData.alternate_number &&
    //   !phoneRegex.test(formData.alternate_number)
    // ) {
    //   errors.alternate_number =
    //     "Invalid Alternate number format. It should be 10 digits.";
    // }

    // if (!image) {
    //   errors.image = "Image is required.";
    // }

    for (const key in formData) {
      if (
        key !== "aadhar_number" &&
        key !== "whatsapp_number" &&
        key !== "email" &&
        key !== "phone" &&
        key !== "dob" &&
        key !== "alternate_number"
      ) {
        if (
          !formData[key] &&
          ["instagram", "facebook", "youtube", "twitter"].indexOf(key) === -1
        ) {
          errors[key] = "This field is required.";
        }
      }
    }

    setFormErrors(errors);

    //   product.forEach((product, index) => {
    //     if (!product.product_id) {
    //       errors[`product_id_${index}`] = "Product Id is required.";
    //     }
    //     if (!product.product_name) {
    //       errors[`product_name_${index}`] = "Product Name is required.";
    //     }
    //     if (!product.brand) {
    //       errors[`brand_${index}`] = "Product Brand is required.";
    //     }

    //     if (!product.product_description) {
    //       errors[`product_description_${index}`] =
    //         "Product Description is required.";
    //     }
    //     if (!product.product_price) {
    //       errors[`product_price_${index}`] = "Product Price is required.";
    //     }

    //     if (!product.product_customization) {
    //       errors[`product_customization_${index}`] =
    //         "Product Customization is required.";
    //     }
    //     if (!product.Rental_Purchase) {
    //       errors[`Rental_Purchase_${index}`] = "Rental/Purchase is required.";
    //     }

    //     if (!product.facilities) {
    //       errors[`facilities_${index}`] = "Facilities is required.";
    //     }
    //     if (!product.available_sizes) {
    //       errors[`available_sizes_${index}`] = "Please Select Size.";
    //     }

    //     if (!product.short_title) {
    //       errors[`short_title_${index}`] = "Short Title is required.";
    //     }

    //     if (!product.short_description) {
    //       errors[`short_description_${index}`] = "Short Title is required.";
    //     }
    //   }
    // );

    setFormErrors(errors);

    // If there are validation errors, don't submit the request
    if (Object.keys(errors).length > 0) {
      return;
    }

    // Prepare form data
    const formdata = new FormData();

    formdata.append("name", formData.shopName);
    formdata.append("city", cityId);
    formdata.append("whatsapp", formData.phone);
    formdata.append("email", formData.email);
    formdata.append("address", formData.address);
    formdata.append("lat", formData.Latitude);
    formdata.append("lan", formData.Longitude);
    formdata.append("description", formData.Description);

    const submitData = async () => {
      try {
        const response = await bookingShopgearPostApi(formdata);
        if (response.status === 201) {
          if (formData.instagram) {
            const socialMediaPaylod = {
              shop_gear: response?.data?.id,
              sc_type: "Instagram",
              link: formData.instagram,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(
                error.response.data.detail
                  ? error.response.data.detail
                  : "Opps! Something Went Wrong, Please try again."
              );
            }
          }
          if (formData.facebook) {
            const socialMediaPaylod = {
              shop_gear: response?.data?.id,
              sc_type: "Facebook",
              link: formData.facebook,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          if (formData.youtube) {
            const socialMediaPaylod = {
              shop_gear: response?.data?.id,
              sc_type: "Youtube",
              link: formData.youtube,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          if (formData.twitter) {
            const socialMediaPaylod = {
              shop_gear: response?.data?.id,
              sc_type: "Twitter",
              link: formData.twitter,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          handleOpenDialog("Form Submited successfully !");
          handleReset();
        } else {
          handleOpenDialog(
            "Failure",
            "Failed to submit the form data. Please try again."
          );
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog(error.response.data.detail);
      }
    };
    submitData();
  };

  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setImage(URL.createObjectURL(selectedFile));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleRoomChange = (index, event) => {
    const { name, value } = event.target;
    const updatedProduct = product.map((product, i) => {
      if (i === index) {
        return { ...product, [name]: value };
      }
      return product;
    });

    setProduct(updatedProduct);

    setFormErrors({
      ...formErrors,
      [`${name}_${index}`]: "",
    });
  };

  const addRoom = (e) => {
    e.preventDefault();
    setProduct((prevProduct) => [
      ...prevProduct,
      {
        product_id: "",
        product_name: "",
        brand: "",
        product_description: "",
        product_price: "",
        discount: "",
        Price: "",
        product_customization: "",
        Rental_Purchase: "",
        facilities: "",
        // short_title: "",
        // short_description: "",
        photos: [{ id: uuidv4(), file: null, url: null }],
        photoIds: [],
      },
    ]);
  };

  const removeRoom = (index) => {
    const updatedProduct = product.filter((_, i) => i !== index);
    setProduct(updatedProduct);
  };

  const addPhotoField = (productIndex) => {
    setProduct((prevProduct) => {
      const updatedProduct = [...prevProduct];
      if (updatedProduct[productIndex].photos.length < 5) {
        updatedProduct[productIndex].photos.push({
          id: uuidv4(),
          file: null,
          url: null,
        });
      }
      return updatedProduct;
    });
  };

  const handlePhotoUpload = async (productIndex, photoIndex, event) => {
    const selectedFile = event.target.files[0];
    try {
      const imageData = new FormData();
      imageData.append("image", selectedFile);

      const response = await imagePostApi(imageData);
      const photoId = response.data.id;

      setProduct((prevProduct) => {
        const updatedProduct = [...prevProduct];
        updatedProduct[productIndex].photos[photoIndex] = {
          ...updatedProduct[productIndex].photos[photoIndex],
          file: selectedFile,
          url: URL.createObjectURL(selectedFile),
          uploaded: true,
          photoId,
        };
        return updatedProduct;
      });
    } catch (error) {
      setError("Error uploading photo");
    }
  };

  const handleDeletePhoto = async (productIndex, photoIndex) => {
    setProduct((prevProduct) => {
      const updatedProduct = [...prevProduct];
      const photo = updatedProduct[productIndex].photos[photoIndex];
      if (photo && photo.uploaded) {
        imageDeleteApi(photo.photoId)
          .then(() => {
            updatedProduct[productIndex].photos = updatedProduct[
              productIndex
            ].photos.filter((_, idx) => idx !== photoIndex);
            setProduct(updatedProduct);
          })
          .catch((error) => {
            setError("Error deleting photo");
          });
      } else {
        updatedProduct[productIndex].photos = updatedProduct[
          productIndex
        ].photos.filter((_, idx) => idx !== photoIndex);
        setProduct(updatedProduct);
      }
      return updatedProduct;
    });
  };

  const handleReset = () => {
    setFormData({
      brand: "",
      shopName: "",
      city: "",
      phone: "",
      alternate_number: "",
      email: "",
      address: "",
      Latitude: "",
      Longitude: "",
      facebook: "",
      youtube: "",
      twitter: "",
      instagram: "",
      Description: "",
    });
    setImage(null);
    setPhotos([{ id: uuidv4(), file: null, url: null }]);
    setProduct([
      {
        product_name: "",
        brand: "",
        product_description: "",
        product_price: "",
        discount: "",
        product_customization: "",
        facilities: "",
        Rental_Purchase: "",
        // short_title: "",
        // short_description: "",
      },
    ]);
    setPhotoIds([]);
    setSelectedCountry("");
    setSelectedState("");
    setFormErrors({});
  };

  const handleDelete = (chipToDelete) => () => {
    setFacilitiesData((chips) =>
      chips.filter((chip) => chip.id !== chipToDelete.id)
    );
  };

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLocation({ latitude, longitude });
  };

  const handleError = (error) => {
    setError(error.message);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h5"
          align="left"
          color="black"
          fontWeight={700}
          gutterBottom
        >
          Kindly fill up these Details
        </Typography>
        <div>
          {/* <div>
            <input
              ref={fileInputRef}
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <button
              className="stay_file_uploader"
              type="button"
              onClick={handleButtonClick}
            >
              {image ? (
                <img src={image} alt="Selected" className="stay_image" />
              ) : (
                <div>
                  <LuImagePlus size={50} style={{ color: "lightgray" }} />
                  <p>Add Image</p>
                </div>
              )}
            </button>
          </div> */}
          <form onSubmit={handleSubmit}>
            <Grid container maxWidth="lg" spacing={2} marginTop={4}>
              <Grid item xs={12} sm={6} md={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Shop Name
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Enter your Shop Name"
                  name="shopName"
                  multiline
                  value={formData.shopName}
                  onChange={handleInputChange}
                />
                {formErrors.shopName && (
                  <Typography variant="body2" color="error">
                    {formErrors.shopName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  City
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl fullWidth>
                  {/* <Select
                    name="city"
                    value={formData?.city}
                    onChange={handleInputChange}
                  >
                    {citiesData &&
                      citiesData?.length > 0 &&
                      citiesData?.map((items, cityIndex) => (
                        <MenuItem key={items?.city_id} value={items?.city_id}>
                          {items?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.city && (
                    <Typography variant="body2" color="error">
                      {formErrors.city}
                    </Typography>
                  )} */}
                  <CityDropdown
                    cityValue={cityName}
                    setCityId={setCityId}
                    style={"stay"}
                    cityName={cityId}
                    error={cityError}
                    setCityError={setCityError}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={5} sm={5} md={5}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Latitude
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  name="Latitude"
                  placeholder="Latitude"
                  value={formData.Latitude}
                  onChange={handleInputChange}
                />
                {formErrors.Latitude && (
                  <Typography variant="body2" color="error">
                    {formErrors.Latitude}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Longitude
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  name="Longitude"
                  placeholder="Longitude"
                  value={formData.Longitude}
                  onChange={handleInputChange}
                />
                {formErrors.Longitude && (
                  <Typography variant="body2" color="error">
                    {formErrors.Longitude}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <div className="geoLocatoin_container">
                  <MdOutlineGpsFixed
                    onClick={getLocation}
                    size={30}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </FormLabel>
                <TextField
                  fullWidth
                  name="phone"
                  placeholder="+91"
                  value={formData.phone ? `${formData.phone}` : ""}
                  onChange={handleInputChange}
                />
                {formErrors.phone && (
                  <Typography variant="body2" color="error">
                    {formErrors.phone}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Alternate Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </FormLabel>
                <TextField
                  fullWidth
                  name="alternate_number"
                  placeholder="+91"
                  value={
                    formData.alternate_number
                      ? `${formData.alternate_number}`
                      : ""
                  }
                  onChange={handleInputChange}
                />
                {formErrors.alternate_number && (
                  <Typography variant="body2" color="error">
                    {formErrors.alternate_number}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Email
                  {/* <span style={{ color: "red" }}>*</span> */}
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="User@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {formErrors.email && (
                  <Typography variant="body2" color="error">
                    {formErrors.email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Address
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Enter you address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
                {formErrors.address && (
                  <Typography variant="body2" color="error">
                    {formErrors.address}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Description
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Description"
                  name="Description"
                  multiline
                  rows={6}
                  value={formData.Description}
                  onChange={handleInputChange}
                />
                {formErrors.Description && (
                  <Typography variant="body2" color="error">
                    {formErrors.Description}
                  </Typography>
                )}
              </Grid>
              {/* <Paper
                style={{ width: "100%" }}
                className="shop_gear_product_main"
              >
                {product?.map((product, productIndex) => (
                  <Container sx={{ mt: 4 }} className="add_product_container">
                    <div className="product_menu">
                      <PopOver
                        openConfirmDialog={openConfirmDialog}
                        setOpenConfirmDialog={setOpenConfirmDialog}
                        setCurrentProductIndex={setCurrentProductIndex}
                        currentIndex={productIndex}
                      />
                    </div>
                    <div key={productIndex} className="product_wrapper">
                      <div>
                        <div className="product_multiImg_main_container">
                          <Grid>
                            <FormLabel
                              sx={{
                                color: "black",
                                fontWeight: "700",
                                marginLeft: 1.5,
                              }}
                            >
                              Image
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                          </Grid>
                          <div className="product_multiImg_container">
                            {product.photos.map((photo, photoIndex) => (
                              <div key={photo.id} className="photo_wrapper">
                                <input
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  id={`photo-upload-${productIndex}-${photoIndex}`}
                                  type="file"
                                  onChange={(event) =>
                                    handlePhotoUpload(
                                      productIndex,
                                      photoIndex,
                                      event
                                    )
                                  }
                                />
                                <div className="additional_photos_wrapper photos_uploader">
                                  <button
                                    className="stay_photos_uploader"
                                    onClick={() =>
                                      document
                                        .getElementById(
                                          `photo-upload-${productIndex}-${photoIndex}`
                                        )
                                        .click()
                                    }
                                    type="button"
                                  >
                                    {photo.url ? (
                                      <img
                                        src={photo.url}
                                        alt={`Additional ${photoIndex + 1}`}
                                        className="stay_image"
                                      />
                                    ) : (
                                      <div>
                                        <LuImagePlus
                                          size={50}
                                          style={{ color: "lightgray" }}
                                        />
                                        <p>Add Image</p>
                                      </div>
                                    )}
                                  </button>
                                  {product?.photos?.length > 1 && (
                                    <RiDeleteBin7Line
                                      size={20}
                                      color="red"
                                      onClick={() =>
                                        handleDeletePhoto(
                                          productIndex,
                                          photoIndex
                                        )
                                      }
                                      className="delete_icon"
                                    />
                                  )}
                                </div>
                                {photosError && (
                                  <p
                                    style={{
                                      color: "#d32f2f",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {photosError}
                                  </p>
                                )}
                              </div>
                            ))}
                            {product.photos.length < 5 && (
                              <CiCirclePlus
                                size={60}
                                style={{
                                  marginBottom: "22px",
                                  color: "#31E3AE",
                                  height: "50px",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => addPhotoField(productIndex)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <Grid container maxWidth="lg" spacing={2}>
                        <Grid item xs={6}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Product Id <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <TextField
                            fullWidth
                            placeholder="Enter Your Product Id"
                            name="product_id"
                            value={product.product_id}
                            onChange={(event) =>
                              handleRoomChange(productIndex, event)
                            }
                            error={Boolean(
                              formErrors[`product_id_${productIndex}`]
                            )}
                            helperText={
                              formErrors[`product_id_${productIndex}`]
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Product Name <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <TextField
                            fullWidth
                            placeholder="Enter Your Product Name"
                            name="product_name"
                            value={product.product_name}
                            onChange={(event) =>
                              handleRoomChange(productIndex, event)
                            }
                            error={Boolean(
                              formErrors[`product_name_${productIndex}`]
                            )}
                            helperText={
                              formErrors[`product_name_${productIndex}`]
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Brand <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <TextField
                            fullWidth
                            placeholder="Enter Ptoduct Brand"
                            name="brand"
                            value={product.brand}
                            onChange={(event) =>
                              handleRoomChange(productIndex, event)
                            }
                            error={Boolean(formErrors[`brand_${productIndex}`])}
                            helperText={formErrors[`brand_${productIndex}`]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Product Description
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <TextField
                            rows={6}
                            fullWidth
                            placeholder="Enter Your Product Description"
                            name="product_description"
                            value={product.product_description}
                            onChange={(event) =>
                              handleRoomChange(productIndex, event)
                            }
                            error={Boolean(
                              formErrors[`product_description_${productIndex}`]
                            )}
                            helperText={
                              formErrors[`product_description_${productIndex}`]
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Product Price
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <TextField
                            type="number"
                            fullWidth
                            placeholder="Enter Your Product Price"
                            name="product_price"
                            value={product.product_price}
                            onChange={(event) =>
                              handleRoomChange(productIndex, event)
                            }
                            error={Boolean(
                              formErrors[`product_price_${productIndex}`]
                            )}
                            helperText={
                              formErrors[`product_price_${productIndex}`]
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Product Type
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <FormControl fullWidth>
                            <Select
                              fullWidth
                              name="product_customization"
                              value={product.product_customization}
                              onChange={(event) =>
                                handleRoomChange(productIndex, event)
                              }
                              error={Boolean(
                                formErrors[
                                  `product_customization_${productIndex}`
                                ]
                              )}
                            >
                              <MenuItem value="shoes">Shoes</MenuItem>
                              <MenuItem value="clothes">Clothes</MenuItem>
                            </Select>
                          </FormControl>
                          {formErrors[
                            `product_customization_${productIndex}`
                          ] && (
                            <Typography variant="body2" color="error">
                              {
                                formErrors[
                                  `product_customization_${productIndex}`
                                ]
                              }
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Rental/Purchase
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <FormControl fullWidth>
                            <Select
                              fullWidth
                              name="Rental_Purchase"
                              value={product.Rental_Purchase}
                              onChange={(event) =>
                                handleRoomChange(productIndex, event)
                              }
                              error={Boolean(
                                formErrors[`Rental_Purchase_${productIndex}`]
                              )}
                            >
                              <MenuItem value="rental">Rental</MenuItem>
                              <MenuItem value="purchase">Purchase</MenuItem>
                            </Select>
                          </FormControl>
                          {formErrors[`Rental_Purchase_${productIndex}`] && (
                            <Typography variant="body2" color="error">
                              {formErrors[`Rental_Purchase_${productIndex}`]}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Discount
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <TextField
                            type="number"
                            fullWidth
                            placeholder="Enter Discount"
                            name="discount"
                            value={product.discount}
                            onChange={(event) =>
                              handleRoomChange(productIndex, event)
                            }
                            error={Boolean(
                              formErrors[`discount_${productIndex}`]
                            )}
                            helperText={formErrors[`discount_${productIndex}`]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            Available Sizes
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <FormControl fullWidth>
                            <Select
                              fullWidth
                              name="available_sizes"
                              value={product.available_sizes}
                              onChange={(event) =>
                                handleRoomChange(productIndex, event)
                              }
                              error={Boolean(
                                formErrors[`available_sizes_${productIndex}`]
                              )}
                            >
                              <MenuItem value="s">S</MenuItem>
                              <MenuItem value="m">M</MenuItem>
                              <MenuItem value="l">L</MenuItem>
                              <MenuItem value="xl">XL</MenuItem>
                              <MenuItem value="xxl">XXL</MenuItem>
                            </Select>
                          </FormControl>
                          {formErrors[`available_sizes_${productIndex}`] && (
                            <Typography variant="body2" color="error">
                              {formErrors[`available_sizes_${productIndex}`]}
                            </Typography>
                          )}
                        </Grid>

                       
                        <Grid item xs={6} sm={8}>
                          <FormLabel
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              marginLeft: 1.5,
                            }}
                          >
                            facilities
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              listStyle: "none",
                              p: 0.5,
                              m: 0,
                              border: "1px solid lightGray",
                              borderRadius: "5px",
                              minHeight: "170px",
                            }}
                            component="ul"
                          >
                            {facilitiesData &&
                              facilitiesData?.length > 0 &&
                              facilitiesData?.map((data) => {
                                let icon;

                                if (data.name === "React") {
                                  // icon = <TagFacesIcon />;
                                }

                                return (
                                  <ListItem key={data.id}>
                                    <Chip
                                      icon={icon}
                                      label={data.name}
                                      onDelete={handleDelete(data)}
                                    />
                                  </ListItem>
                                );
                              })}
                          </Container>
                          {formErrors.Amenities && (
                            <Typography variant="body2" color="error">
                              {formErrors.Amenities}
                            </Typography>
                          )}
                        </Grid>

                        <div className="add_icon_container"></div>
                      </Grid>
                    </div>
                  </Container>
                ))}
                <div className="add_room_button_container">
                  <button onClick={addRoom} className="add_room_button">
                    Add More
                  </button>
                </div>
              </Paper> */}

              <div className="socialmedia_main_container">
                <FormLabel
                  sx={{
                    color: "black",
                    fontWeight: "700",
                    marginLeft: 1.5,
                  }}
                >
                  Social Media URL
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Grid
                  container
                  maxWidth="lg"
                  spacing={2}
                  style={{ marginTop: "1px" }}
                >
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Facebook"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="facebook"
                      placeholder="Enter Url"
                      value={formData.facebook}
                      onChange={handleInputChange}
                    />
                    {formErrors.facebook && (
                      <Typography variant="body2" color="error">
                        {formErrors.facebook}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Instagram"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="instagram"
                      placeholder="Enter Url"
                      value={formData.instagram}
                      onChange={handleInputChange}
                    />
                    {formErrors.instagram && (
                      <Typography variant="body2" color="error">
                        {formErrors.instagram}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Youtube"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="youtube"
                      placeholder="Enter Url"
                      value={formData.youtube}
                      onChange={handleInputChange}
                    />
                    {formErrors.youtube && (
                      <Typography variant="body2" color="error">
                        {formErrors.youtube}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Twitter"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="twitter"
                      placeholder="Enter Url"
                      value={formData.twitter}
                      onChange={handleInputChange}
                    />
                    {formErrors.twitter && (
                      <Typography variant="body2" color="error">
                        {formErrors.twitter}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Container className="stay_buton_container">
                <div className="stay_buton_container_childs">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#31E3AE",
                      border: "1px solid #31E3AE",
                      marginBottom: "22px",
                      padding: "8px 30px ",
                    }}
                    onClick={handleReset}
                  >
                    Reset All
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      backgroundColor: "#31E3AE",
                      marginBottom: "22px",
                      padding: "8px 30px ",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Container>
            </Grid>
          </form>
          <ConfirmationDialog
            onDelete={removeRoom}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            currentProductIndex={currentProductIndex}
          />
          <DialogBox
            open={openDialog}
            onClose={handleCloseDialog}
            title={dialogContent.title}
            content={dialogContent.message}
          />
        </div>
      </Container>
    </>
  );
};

export default ShopGearForm;
