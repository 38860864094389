import React, { useEffect, useState } from "react";
import MobileAppFeatures from "../Components/MobileAppFeatures";
import MobileAppFeaturesTwo from "../Components/MobileAppFeaturesTwo";
import BarCode from "../Components/BarCode";
import Faq from "../Components/Faq";
import "../Style/Community.css";
import { display } from "@mui/system";
import DialogBox from "../Components/DialogBox";
export default function Community({ postId }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });
  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleLike = () => {
    handleOpenDialog("Liked", "To know more download GoWild App.");
  };
  useEffect(() => {
    async function fetchInfo() {
      try {
        var requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const response = await fetch(
          `https://orca-app-pgxkr.ondigitalocean.app/website/web-post/${postId}`,
          requestOptions
        );
        const result = await response.json();
        if (response.ok) {
          console.log("result", result);
          setData(result);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, []);

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  // if (!data) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div>
      {console.log("data", data[0])}
      <div className="image-container">
        <img
          src={require("../Images/headerimg.png")}
          alt=""
          style={{ width: "100%", height: "auto" }}
        />
        <div className="privacy-policy-heading">Community Post</div>
      </div>
      <div className="container2">
        {/* <img src={require("../Images/balun.png")} alt="" className="balun1" />
        <img src={require("../Images/balun.png")} alt="" className="balun2" />
        <img src={require("../Images/balun.png")} alt="" className="balun3" /> */}
        <div className="feed-container">
          <div className="post">
            <div className="post-header">
              <div className="person-img">
                <img
                  src={data[0]?.user_id?.image || data[0]?.image}
                  alt=""
                  className="person-img2"
                />
              </div>
              <div className="user-info">
                <h1 className="user-name">{data[0]?.user_id.name}</h1>
                <div className="location">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 21 25"
                    fill="none"
                  >
                    <path
                      d="M10.3333 0.75C7.79262 0.753009 5.3568 1.76364 3.56022 3.56022C1.76355 5.35689 0.752907 7.79286 0.75 10.3337C0.750103 11.5134 1.22461 12.8727 2.02629 14.3066C2.82195 15.7298 3.90314 17.1618 5.03128 18.4704C7.28534 21.085 9.66575 23.1372 10.1502 23.5485C10.1505 23.5488 10.1509 23.5491 10.1512 23.5494L10.3333 23.7033L10.5155 23.5494C10.5159 23.549 10.5163 23.5487 10.5167 23.5483C10.5167 23.5483 10.5167 23.5483 10.5167 23.5483C11.0017 23.1365 13.3817 21.0847 15.6354 18.4704C16.7636 17.1618 17.8447 15.7298 18.6404 14.3066C19.442 12.8729 19.9165 11.5137 19.9167 10.3342L10.3333 0.75ZM10.3333 0.75C12.8741 0.753009 15.3099 1.76364 17.1065 3.56022C18.9032 5.35698 19.9139 7.79311 19.9167 10.3341L10.3333 0.75ZM10.3333 16.25H10.3342C11.9026 16.2481 13.4062 15.6243 14.5153 14.5153C15.6243 13.4062 16.2481 11.9026 16.25 10.3342V10.3333C16.25 9.16314 15.903 8.01921 15.2529 7.04622C14.6027 6.07323 13.6787 5.31487 12.5976 4.86705C11.5164 4.41923 10.3268 4.30206 9.17906 4.53036C8.03134 4.75866 6.97709 5.32216 6.14962 6.14962C5.32216 6.97709 4.75866 8.03134 4.53036 9.17906C4.30206 10.3268 4.41923 11.5164 4.86705 12.5976C5.31487 13.6787 6.07323 14.6027 7.04622 15.2529C8.01921 15.903 9.16314 16.25 10.3333 16.25Z"
                      stroke="#A9A9A9"
                      stroke-width="1.5"
                    />
                  </svg>
                  <p className="location-text">{data[0]?.location}</p>
                </div>
              </div>
            </div>
            <div className="post-content">
              <p className="post-description">{data.description}</p>
              <img
                src={data[0]?.image || require("../Images/background1.png")}
                alt=""
                className="community-img"
              />
            </div>
            <div className="post-footer">
              <div className="like-section" onClick={handleLike}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                    fill="#616161"
                  />
                </svg>
                <div>{data[0]?.number_of_likes} Likes</div>
              </div>
              <div className="comments-section">
                {data[0]?.latest_comments?.length > 0 ? (
                  data[0].latest_comments.map((comment) => (
                    <div key={comment.id} className="comment-container">
                      <div className="person-img3">
                        <img
                          src={comment?.user_id?.image}
                          alt=""
                          className="person-img2"
                        />
                      </div>
                      <div className="comment-details">
                        <div className="comment-name">{comment?.user_id?.name}</div>
                        <div className="comment-text">{comment?.comment}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-comment">No comments to show.</div>
                )}
              </div>
            </div>
          </div>
          <DialogBox
            open={openDialog}
            onClose={handleCloseDialog}
            title={dialogContent.title}
            content={dialogContent.message}
          />
        </div>
      </div>
      <MobileAppFeatures />
      <MobileAppFeaturesTwo />
      <BarCode />
      <Faq />
    </div>
  );
}
