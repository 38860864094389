// import React, { useEffect, useState } from "react";
// import "../Style/MobileAppFeatures.css";
// import CustomImageTextButton from "../Components/CustomTextImageButton";

// export default function MobileAppFeatures() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

//   useEffect(() => {
//     function handleResize() {
//       setIsMobile(window.innerWidth <= 992);
//     }

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleButtonClick = () => {
//     // Add your click event logic here
//     alert("Button clicked!");
//   };

//   return (
//     <>
//       {isMobile ? (
//         <div className="mobile_main_container_with_icon">
//           <div className="mobile_main_container">
//             <div className="mobile_images_div">
//               <p>App Features</p>
//               {/* <div className="mobile_side_icon">
//                   <img src={require("../Images/Frame.png")} alt="#" />
//                 </div> */}

//               <div>
//                 <img
//                   className="mob1_img"
//                   src={require("../Images/mobile1.png")}
//                   alt=""
//                 />
//                 <img
//                   className="mob2_img"
//                   src={require("../Images/mobile2.png")}
//                   alt=""
//                 />
//               </div>
//             </div>

//             <div className="mobile_text">
//               <p>Discover Hidden games</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <p>Record Your Journey</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <p>Connect with Advantures</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>

//               <CustomImageTextButton
//                 text="Learn More"
//                 imageSrc={require("../Images/Arrow 4.png")}
//                 height="auto"
//                 width="auto"
//                 onClick={handleButtonClick}
//               />
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="mobile_main_container_with_icon">
//           <div className="mobile_main_container">
//             <div className="mobile_images_div">
//               <div className="mobile_image1">
//                 <p>App Features</p>
//                 <img
//                   className="mob1_img"
//                   src={require("../Images/mobile1.png")}
//                   alt=""
//                 />
//                 <img
//                   className="mob2_img"
//                   src={require("../Images/mobile2.png")}
//                   alt=""
//                 />
//               </div>
//             </div>
//             <div className="mobile_text">
//               <p>Discover Hidden games</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <p>Record Your Journey</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <p>Connect with Advantures</p>
//               <li className="bullet-list-item">
//                 <span className="bullet-icon">•</span> Lorem Ipsum is simply
//                 dummy text of the printing and typesetting industry. Lorem Ipsum
//                 has been the industry's standard dummy text Lorem Ipsum is
//                 simply dummy text of the printing and typesetting industry.
//                 Lorem Ipsum has been the industry's stand
//               </li>
//               <div className="button">
//                 <CustomImageTextButton
//                   text="Learn More"
//                   imageSrc={require("../Images/Arrow 4.png")}
//                   height="auto"
//                   width="auto"
//                   onClick={handleButtonClick}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="mobile_side_icon">
//             <img src={require("../Images/Frame.png")} alt="#" />
//           </div>
//         </div>
//       )}
//     </>
//   );
// }
import React from "react";
import "../Style/MobileAppFeatures.css";
import CustomImageTextButton from "../Components/CustomTextImageButton";
export default function MobileAppFeatures() {
  return (
    <div id="feauture" className="container">
      <p className="heading">App Features</p>
      <div className="sub-container1 img-box1">
        <div className="twoMobile">
          <img
            className="mob1_img"
            src={require("../Images/mobile1.png")}
            alt=""
          />
          <img
            className="mob2_img"
            src={require("../Images/mobile2.png")}
            alt=""
          />
        </div>
        <div className="twoMobileText">
          <p className="subHeading">Discover Hidden Gems</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Uncover hidden treasures and
            popular destinations within your chosen radius. From tranquil lakes
            to thrilling hiking trails, we've got your adventure covered.
          </p>
          <p className="subHeading">Record Your Journey</p>
          <p className="para bullet-list-item">
            {" "}
            <span className="bullet-icon">•</span> Capture the magic of your
            outdoor experiences with ease. Record your route, add photos, and
            document your adventures to relive and share with fellow explorers.
          </p>
          <div className="hide-on-phone">
            <p className="subHeading ">Connect with Adventurers</p>
            <p className="  para   bullet-list-item  ">
              <span className=" bullet-icon">•</span> Forge connections with
              like-minded individuals who share your passion for the great
              outdoors. Follow their journeys, exchange stories, and...
            </p>
          </div>
          <div className="button">
            <CustomImageTextButton
              text="Learn More"
              imageSrc={require("../Images/Arrow 4.png")}
              height="auto"
              width="auto"
              // onClick={handleButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
