import React, { useState } from "react";
import "../Style/Navbar.css";
import { BiLogoFacebook } from "react-icons/bi";
import { FaTwitter, FaWhatsapp } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [mob, setMob] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Home");
  const toggleNavbar = () => {
    setMob(!mob);
  };

  const closeNavbar = () => {
    setMob(false);
  };
  const handleMenuClick = (menuItem) => {
    setSelectedMenu(menuItem);
  };
  return (
    <>
      <div className="navbar_back">
        <div className="navbar_items navbar_title">
          <div>
            <Link to={"/"}>
              <img
                className="logo_img"
                src={require("../Images/gowild_logo.png")}
                alt="#"
              />
            </Link>
          </div>
          {mob ? (
            <ul className="mob_menu">
              <div className="mob_menu_back_image" onClick={closeNavbar}>
                <img src={require("../Images/right_arrow.png")} alt="" />
                <li onClick={() => handleMenuClick("Home")}>Home</li>
              </div>
              <li onClick={() => handleMenuClick("Features")}>Features</li>
              <li onClick={() => handleMenuClick("About Us")}>About Us</li>
              <li onClick={() => handleMenuClick("Contact Us")}>Contact Us</li>
              <li onClick={() => handleMenuClick("Log In")}>Log In</li>
              <li onClick={() => handleMenuClick("Settings")}>Settings</li>
            </ul>
          ) : (
            <ul className="menu">
              <li
                className={`menu_back_image ${
                  selectedMenu === "Home" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("Home")}
              >
                <Link to={"/"}>Home</Link>
              </li>

              <li
                className={`menu_back_image ${
                  selectedMenu === "Features" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("Features")}
              >
                <a href="#feauture">Features</a>
              </li>
              <li
                className={`menu_back_image ${
                  selectedMenu === "About Us" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("About Us")}
              >
                <a href="#faq">About Us</a>
              </li>

              <li
                className={`menu_back_image ${
                  selectedMenu === "Become a Guide" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("Become a Guide")}
              >
                <Link to={"/guide"}>Become a Guide</Link>
              </li>
            </ul>
          )}

          <div className="hemBurger" onClick={toggleNavbar}>
            {mob ? (
              <img src={require("../Images/burgur.png")} alt="" />
            ) : (
              <img src={require("../Images/burgur.png")} alt="" />
            )}
          </div>
          <div className="navbar_icons">
            <a
              href="https://youtube.com/@GoWildExplorer?si=_HkwM3Zb04u6oDEW"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a
              href="https://x.com/GoWildexplorer?s=20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a
              href="https://www.instagram.com/gowild_explorer/?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://wa.me/8979951459"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
