import React from "react";
import "../Style/Home.css";
import Navbar from "./Navbar";
import MobileAppFeatures from "./MobileAppFeatures";
import Cart from "./Cart";
import Footer from "./Footer";
import BarCode from "./BarCode";
import MobileAppFeaturesTwo from "./MobileAppFeaturesTwo";
import Faq from "./Faq";
export default function Home() {
  return (
    <>
      {/* mobile view */}
      <div className="mobile_second_component">
        <div className="mobile_background_text">
          <h6>Welcome to GoWild!</h6>
          <p>
            Where adventure knows no bounds. With us, you'll embark on journeys
            of discovery, where the unexplored becomes your playground.
          </p>
          <p>
            Dive into the beauty of untouched landscapes, wander through hidden
            trails, and unlock the secrets of the world's most extraordinary
            destinations.
          </p>
          <p>
            Unleash your inner explorer and redefine what's possible. Begin your
            odyssey today.
          </p>
          <h3>Discover, Explore, Go Wild!"</h3>
          <button>Explore Now</button>
        </div>
      </div>
      {/* dextop view */}
      <div className="second_component">
        <div className="background_text">
          <h6>"Go Wild:</h6>
          <h3>Discover, Explore, Go Wild!"</h3>
          <p>
            Welcome to 'Go WIld ,' where adventure knows no bounds. With us,
            you'll embark on journeys of discovery, where the unexplored becomes
            your playground. Dive into the beauty of untouched landscapes,
            wander through hidden trails, and unlock the secrets of the world's
            most extraordinary destinations. Unleash your inner explorer and
            redefine what's possible. Begin your odyssey today.
          </p>
          <button>Explore Now</button>
        </div>
      </div>
      <Cart />
      <MobileAppFeatures />
      <MobileAppFeaturesTwo />
      <BarCode />

      <Faq />
    </>
  );
}
