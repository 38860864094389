import React, { useEffect, useState } from "react";

import "../Style/Cart.css";
import ActButton from "./ActButton";
export default function ImgBox({
  image,
  rating,
  visitor,
  description,
  name,
  place,
  activities,
  hide,
  index,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1282);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1282);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleExpand = () => {
    console.log("index", index);
    setIsExpanded(!isExpanded);
  };
  const truncateText = (text, limit) => {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };

  const truncatedDescription = truncateText(description, 38);

  const truncatedHeading = truncateText(name, 4);
  return (
    <>
      {isExpanded ? (
        <div className="flex card-container">
          {/* {(index + 1) % 3 !== 0 && ( */}
          <>
            <div className={`cartBox back-card ${isExpanded ? "hidden" : ""}`}>
              <div className="imageBox2">
                <img src={image} alt="img" className="fit-image2" />
              </div>
            </div>
            <div className={`cartBox2 ${isExpanded ? "expanded" : ""}`}>
              <div className="img-container2 justify-between">
                <div className="visitor-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                  >
                    <path
                      d="M6.12484 8.16667C6.93245 8.16667 7.72192 7.92719 8.39342 7.4785C9.06492 7.02982 9.58829 6.39209 9.89735 5.64596C10.2064 4.89983 10.2873 4.07881 10.1297 3.28672C9.97216 2.49463 9.58326 1.76705 9.01219 1.19598C8.44113 0.624918 7.71355 0.236018 6.92146 0.078462C6.12937 -0.0790944 5.30835 0.00176925 4.56221 0.310827C3.81608 0.619885 3.17835 1.14326 2.72967 1.81476C2.28099 2.48626 2.0415 3.27573 2.0415 4.08334C2.04258 5.16597 2.47314 6.20396 3.23868 6.9695C4.00422 7.73503 5.0422 8.16559 6.12484 8.16667ZM6.12484 1.36111C6.66324 1.36111 7.18956 1.52077 7.63722 1.81989C8.08489 2.11901 8.4338 2.54417 8.63984 3.04159C8.84588 3.53901 8.89979 4.08636 8.79475 4.61442C8.68972 5.14247 8.43045 5.62753 8.04974 6.00824C7.66903 6.38895 7.18398 6.64821 6.65592 6.75325C6.12786 6.85829 5.58051 6.80438 5.08309 6.59834C4.58567 6.3923 4.16051 6.04339 3.86139 5.59572C3.56227 5.14805 3.40262 4.62174 3.40262 4.08334C3.40262 3.36136 3.68942 2.66895 4.19994 2.15843C4.71045 1.64792 5.40286 1.36111 6.12484 1.36111Z"
                      fill="#06C8F7"
                    />
                    <path
                      d="M6.125 8.52783C4.5011 8.52963 2.94423 9.17552 1.79596 10.3238C0.64769 11.4721 0.00180133 13.0289 0 14.6528C0 14.8333 0.0717012 15.0064 0.19933 15.1341C0.326959 15.2617 0.500061 15.3334 0.680556 15.3334C0.86105 15.3334 1.03415 15.2617 1.16178 15.1341C1.28941 15.0064 1.36111 14.8333 1.36111 14.6528C1.36111 13.3894 1.86302 12.1777 2.75642 11.2843C3.64982 10.3909 4.86154 9.88894 6.125 9.88894C7.38846 9.88894 8.60018 10.3909 9.49358 11.2843C10.387 12.1777 10.8889 13.3894 10.8889 14.6528C10.8889 14.8333 10.9606 15.0064 11.0882 15.1341C11.2158 15.2617 11.389 15.3334 11.5694 15.3334C11.7499 15.3334 11.923 15.2617 12.0507 15.1341C12.1783 15.0064 12.25 14.8333 12.25 14.6528C12.2482 13.0289 11.6023 11.4721 10.454 10.3238C9.30577 9.17552 7.7489 8.52963 6.125 8.52783Z"
                      fill="#06C8F7"
                    />
                  </svg>
                  <span style={{ paddingLeft: "4px" }}>{visitor} visitors</span>
                </div>
                {/* <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="16"
                    viewBox="0 0 21 19"
                    fill="none"
                  >
                    <path
                      d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
                      fill="#FCC534"
                    />
                  </svg>
                  <span style={{ fontSize: "14px" }}>{rating}</span>
                </div> */}
              </div>
              <div className="textbox">
                <p className="box-name">{truncatedHeading}</p>
                <div className="places-box2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 21 25"
                    fill="none"
                  >
                    <path
                      d="M10.3333 0.75C7.79262 0.753009 5.3568 1.76364 3.56022 3.56022C1.76355 5.35689 0.752907 7.79286 0.75 10.3337C0.750103 11.5134 1.22461 12.8727 2.02629 14.3066C2.82195 15.7298 3.90314 17.1618 5.03128 18.4704C7.28534 21.085 9.66575 23.1372 10.1502 23.5485C10.1505 23.5488 10.1509 23.5491 10.1512 23.5494L10.3333 23.7033L10.5155 23.5494C10.5159 23.549 10.5163 23.5487 10.5167 23.5483C10.5167 23.5483 10.5167 23.5483 10.5167 23.5483C11.0017 23.1365 13.3817 21.0847 15.6354 18.4704C16.7636 17.1618 17.8447 15.7298 18.6404 14.3066C19.442 12.8729 19.9165 11.5137 19.9167 10.3342L10.3333 0.75ZM10.3333 0.75C12.8741 0.753009 15.3099 1.76364 17.1065 3.56022C18.9032 5.35698 19.9139 7.79311 19.9167 10.3341L10.3333 0.75ZM10.3333 16.25H10.3342C11.9026 16.2481 13.4062 15.6243 14.5153 14.5153C15.6243 13.4062 16.2481 11.9026 16.25 10.3342V10.3333C16.25 9.16314 15.903 8.01921 15.2529 7.04622C14.6027 6.07323 13.6787 5.31487 12.5976 4.86705C11.5164 4.41923 10.3268 4.30206 9.17906 4.53036C8.03134 4.75866 6.97709 5.32216 6.14962 6.14962C5.32216 6.97709 4.75866 8.03134 4.53036 9.17906C4.30206 10.3268 4.41923 11.5164 4.86705 12.5976C5.31487 13.6787 6.07323 14.6027 7.04622 15.2529C8.01921 15.903 9.16314 16.25 10.3333 16.25Z"
                      stroke="#A9A9A9"
                      stroke-width="1.5"
                    />
                  </svg>
                  <p className="placeText">{place}</p>
                </div>
                <div className="cart-para">{truncatedDescription}</div>

                <ActButton text={activities} />
                <span className="explore" onClick={handleExpand}>
                  Explore{"  "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="12"
                    viewBox="0 0 33 13"
                    fill="none"
                  >
                    <path
                      d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.2929L18.3431 0.928934C17.9526 0.538409 17.3195 0.538409 16.9289 0.928934C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM-8.74228e-08 9L24 9L24 7L8.74228e-08 7L-8.74228e-08 9Z"
                      fill="#06C8F7"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </>
          {/* )} */}
          {/* {(index + 1) % 3 === 0 && (
            <div className=" absolute">
              <div
                className={`cartBox back-card ${isExpanded ? "hidden" : ""}`}
              >
                <div className="imageBox2">
                  <img src={image} alt="img" className="fit-image2" />
                </div>
              </div>
              <div className={`cartBox2 ${isExpanded ? "expanded" : ""}`}>
                <div className="img-container2 justify-between">
                  <div className="visitor-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="16"
                      viewBox="0 0 13 16"
                      fill="none"
                    >
                      <path
                        d="M6.12484 8.16667C6.93245 8.16667 7.72192 7.92719 8.39342 7.4785C9.06492 7.02982 9.58829 6.39209 9.89735 5.64596C10.2064 4.89983 10.2873 4.07881 10.1297 3.28672C9.97216 2.49463 9.58326 1.76705 9.01219 1.19598C8.44113 0.624918 7.71355 0.236018 6.92146 0.078462C6.12937 -0.0790944 5.30835 0.00176925 4.56221 0.310827C3.81608 0.619885 3.17835 1.14326 2.72967 1.81476C2.28099 2.48626 2.0415 3.27573 2.0415 4.08334C2.04258 5.16597 2.47314 6.20396 3.23868 6.9695C4.00422 7.73503 5.0422 8.16559 6.12484 8.16667ZM6.12484 1.36111C6.66324 1.36111 7.18956 1.52077 7.63722 1.81989C8.08489 2.11901 8.4338 2.54417 8.63984 3.04159C8.84588 3.53901 8.89979 4.08636 8.79475 4.61442C8.68972 5.14247 8.43045 5.62753 8.04974 6.00824C7.66903 6.38895 7.18398 6.64821 6.65592 6.75325C6.12786 6.85829 5.58051 6.80438 5.08309 6.59834C4.58567 6.3923 4.16051 6.04339 3.86139 5.59572C3.56227 5.14805 3.40262 4.62174 3.40262 4.08334C3.40262 3.36136 3.68942 2.66895 4.19994 2.15843C4.71045 1.64792 5.40286 1.36111 6.12484 1.36111Z"
                        fill="#06C8F7"
                      />
                      <path
                        d="M6.125 8.52783C4.5011 8.52963 2.94423 9.17552 1.79596 10.3238C0.64769 11.4721 0.00180133 13.0289 0 14.6528C0 14.8333 0.0717012 15.0064 0.19933 15.1341C0.326959 15.2617 0.500061 15.3334 0.680556 15.3334C0.86105 15.3334 1.03415 15.2617 1.16178 15.1341C1.28941 15.0064 1.36111 14.8333 1.36111 14.6528C1.36111 13.3894 1.86302 12.1777 2.75642 11.2843C3.64982 10.3909 4.86154 9.88894 6.125 9.88894C7.38846 9.88894 8.60018 10.3909 9.49358 11.2843C10.387 12.1777 10.8889 13.3894 10.8889 14.6528C10.8889 14.8333 10.9606 15.0064 11.0882 15.1341C11.2158 15.2617 11.389 15.3334 11.5694 15.3334C11.7499 15.3334 11.923 15.2617 12.0507 15.1341C12.1783 15.0064 12.25 14.8333 12.25 14.6528C12.2482 13.0289 11.6023 11.4721 10.454 10.3238C9.30577 9.17552 7.7489 8.52963 6.125 8.52783Z"
                        fill="#06C8F7"
                      />
                    </svg>
                    <span style={{ paddingLeft: "4px" }}>
                      {visitor} visitors
                    </span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="16"
                      viewBox="0 0 21 19"
                      fill="none"
                    >
                      <path
                        d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
                        fill="#FCC534"
                      />
                    </svg>
                    <span style={{ fontSize: "14px" }}>{rating}</span>
                  </div>
                </div>
                <div className="textbox">
                  <p className="box-name">{name}</p>
                  <div className="places-box2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 21 25"
                      fill="none"
                    >
                      <path
                        d="M10.3333 0.75C7.79262 0.753009 5.3568 1.76364 3.56022 3.56022C1.76355 5.35689 0.752907 7.79286 0.75 10.3337C0.750103 11.5134 1.22461 12.8727 2.02629 14.3066C2.82195 15.7298 3.90314 17.1618 5.03128 18.4704C7.28534 21.085 9.66575 23.1372 10.1502 23.5485C10.1505 23.5488 10.1509 23.5491 10.1512 23.5494L10.3333 23.7033L10.5155 23.5494C10.5159 23.549 10.5163 23.5487 10.5167 23.5483C10.5167 23.5483 10.5167 23.5483 10.5167 23.5483C11.0017 23.1365 13.3817 21.0847 15.6354 18.4704C16.7636 17.1618 17.8447 15.7298 18.6404 14.3066C19.442 12.8729 19.9165 11.5137 19.9167 10.3342L10.3333 0.75ZM10.3333 0.75C12.8741 0.753009 15.3099 1.76364 17.1065 3.56022C18.9032 5.35698 19.9139 7.79311 19.9167 10.3341L10.3333 0.75ZM10.3333 16.25H10.3342C11.9026 16.2481 13.4062 15.6243 14.5153 14.5153C15.6243 13.4062 16.2481 11.9026 16.25 10.3342V10.3333C16.25 9.16314 15.903 8.01921 15.2529 7.04622C14.6027 6.07323 13.6787 5.31487 12.5976 4.86705C11.5164 4.41923 10.3268 4.30206 9.17906 4.53036C8.03134 4.75866 6.97709 5.32216 6.14962 6.14962C5.32216 6.97709 4.75866 8.03134 4.53036 9.17906C4.30206 10.3268 4.41923 11.5164 4.86705 12.5976C5.31487 13.6787 6.07323 14.6027 7.04622 15.2529C8.01921 15.903 9.16314 16.25 10.3333 16.25Z"
                        stroke="#A9A9A9"
                        stroke-width="1.5"
                      />
                    </svg>
                    <p className="placeText">{place}</p>
                  </div>
                  <div className="cart-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown.
                  </div>

                  <ActButton text={activities} />
                  <span className="explore" onClick={handleExpand}>
                    Explore{"  "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="12"
                      viewBox="0 0 33 13"
                      fill="none"
                    >
                      <path
                        d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.2929L18.3431 0.928934C17.9526 0.538409 17.3195 0.538409 16.9289 0.928934C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM-8.74228e-08 9L24 9L24 7L8.74228e-08 7L-8.74228e-08 9Z"
                        fill="#06C8F7"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div className={`cartBox ${hide}`}>
          <div className="imageBox">
            <img src={image} alt="img" className="fit-image" />
          </div>
          <div className="visitor">
            <div className="visitor-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
              >
                <path
                  d="M6.12484 8.16667C6.93245 8.16667 7.72192 7.92719 8.39342 7.4785C9.06492 7.02982 9.58829 6.39209 9.89735 5.64596C10.2064 4.89983 10.2873 4.07881 10.1297 3.28672C9.97216 2.49463 9.58326 1.76705 9.01219 1.19598C8.44113 0.624918 7.71355 0.236018 6.92146 0.078462C6.12937 -0.0790944 5.30835 0.00176925 4.56221 0.310827C3.81608 0.619885 3.17835 1.14326 2.72967 1.81476C2.28099 2.48626 2.0415 3.27573 2.0415 4.08334C2.04258 5.16597 2.47314 6.20396 3.23868 6.9695C4.00422 7.73503 5.0422 8.16559 6.12484 8.16667ZM6.12484 1.36111C6.66324 1.36111 7.18956 1.52077 7.63722 1.81989C8.08489 2.11901 8.4338 2.54417 8.63984 3.04159C8.84588 3.53901 8.89979 4.08636 8.79475 4.61442C8.68972 5.14247 8.43045 5.62753 8.04974 6.00824C7.66903 6.38895 7.18398 6.64821 6.65592 6.75325C6.12786 6.85829 5.58051 6.80438 5.08309 6.59834C4.58567 6.3923 4.16051 6.04339 3.86139 5.59572C3.56227 5.14805 3.40262 4.62174 3.40262 4.08334C3.40262 3.36136 3.68942 2.66895 4.19994 2.15843C4.71045 1.64792 5.40286 1.36111 6.12484 1.36111Z"
                  fill="#06C8F7"
                />
                <path
                  d="M6.125 8.52783C4.5011 8.52963 2.94423 9.17552 1.79596 10.3238C0.64769 11.4721 0.00180133 13.0289 0 14.6528C0 14.8333 0.0717012 15.0064 0.19933 15.1341C0.326959 15.2617 0.500061 15.3334 0.680556 15.3334C0.86105 15.3334 1.03415 15.2617 1.16178 15.1341C1.28941 15.0064 1.36111 14.8333 1.36111 14.6528C1.36111 13.3894 1.86302 12.1777 2.75642 11.2843C3.64982 10.3909 4.86154 9.88894 6.125 9.88894C7.38846 9.88894 8.60018 10.3909 9.49358 11.2843C10.387 12.1777 10.8889 13.3894 10.8889 14.6528C10.8889 14.8333 10.9606 15.0064 11.0882 15.1341C11.2158 15.2617 11.389 15.3334 11.5694 15.3334C11.7499 15.3334 11.923 15.2617 12.0507 15.1341C12.1783 15.0064 12.25 14.8333 12.25 14.6528C12.2482 13.0289 11.6023 11.4721 10.454 10.3238C9.30577 9.17552 7.7489 8.52963 6.125 8.52783Z"
                  fill="#06C8F7"
                />
              </svg>
              <span style={{ paddingLeft: "4px" }}>{visitor} visitors</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="16"
                viewBox="0 0 21 19"
                fill="none"
              >
                <path
                  d="M10.5 0L12.8574 7.25532H20.4861L14.3143 11.7394L16.6717 18.9947L10.5 14.5106L4.32825 18.9947L6.68565 11.7394L0.513906 7.25532H8.1426L10.5 0Z"
                  fill="#FCC534"
                />
              </svg>
              <span style={{ fontSize: "14px" }}>{rating}</span>
            </div>
          </div>
          <div className="textbox">
            <p className="box-name">{truncatedHeading}</p>
            <div className="places-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 21 25"
                fill="none"
              >
                <path
                  d="M10.3333 0.75C7.79262 0.753009 5.3568 1.76364 3.56022 3.56022C1.76355 5.35689 0.752907 7.79286 0.75 10.3337C0.750103 11.5134 1.22461 12.8727 2.02629 14.3066C2.82195 15.7298 3.90314 17.1618 5.03128 18.4704C7.28534 21.085 9.66575 23.1372 10.1502 23.5485C10.1505 23.5488 10.1509 23.5491 10.1512 23.5494L10.3333 23.7033L10.5155 23.5494C10.5159 23.549 10.5163 23.5487 10.5167 23.5483C10.5167 23.5483 10.5167 23.5483 10.5167 23.5483C11.0017 23.1365 13.3817 21.0847 15.6354 18.4704C16.7636 17.1618 17.8447 15.7298 18.6404 14.3066C19.442 12.8729 19.9165 11.5137 19.9167 10.3342L10.3333 0.75ZM10.3333 0.75C12.8741 0.753009 15.3099 1.76364 17.1065 3.56022C18.9032 5.35698 19.9139 7.79311 19.9167 10.3341L10.3333 0.75ZM10.3333 16.25H10.3342C11.9026 16.2481 13.4062 15.6243 14.5153 14.5153C15.6243 13.4062 16.2481 11.9026 16.25 10.3342V10.3333C16.25 9.16314 15.903 8.01921 15.2529 7.04622C14.6027 6.07323 13.6787 5.31487 12.5976 4.86705C11.5164 4.41923 10.3268 4.30206 9.17906 4.53036C8.03134 4.75866 6.97709 5.32216 6.14962 6.14962C5.32216 6.97709 4.75866 8.03134 4.53036 9.17906C4.30206 10.3268 4.41923 11.5164 4.86705 12.5976C5.31487 13.6787 6.07323 14.6027 7.04622 15.2529C8.01921 15.903 9.16314 16.25 10.3333 16.25Z"
                  stroke="#A9A9A9"
                  stroke-width="1.5"
                />
              </svg>
              <p className="placeText">{place}</p>
            </div>

            <ActButton text={activities} />
            <span className="explore" onClick={handleExpand}>
              Explore{"  "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 33 13"
                fill="none"
              >
                <path
                  d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.2929L18.3431 0.928934C17.9526 0.538409 17.3195 0.538409 16.9289 0.928934C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM-8.74228e-08 9L24 9L24 7L8.74228e-08 7L-8.74228e-08 9Z"
                  fill="#06C8F7"
                />
              </svg>
            </span>
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
}
