import React, { useState } from "react";
import "../Style/Faq.css";
import Accordion from "./Accordion";
import { useNavigate } from "react-router-dom";
import DialogBox from "./DialogBox";
export default function Faq() {
  const [selectedOption, setSelectedOption] = useState("email");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const faqs = [
    {
      question: "What is 'My App Name,' and how does it work?",
      answer:
        "'Go Wild' is a travel and outdoor activity platform that helps you discover exciting places, record your adventures, connect with fellow adventurers, attend activity classes, and hire experienced local guides. It works by using geolocation services to find popular places nearby, allowing you to explore and document your outdoor experiences.",
    },
    {
      question: "How can I record my outdoor adventures using the app?",
      answer:
        "To record your adventures, simply start a route while engaging in outdoor activities like hiking or biking. You can add photos and videos to your route as you progress, creating a visual journey of your experience.",
    },
    {
      question: "What's the benefit of connecting with other users on the app?",
      answer:
        " Connecting with other users allows you to share your outdoor experiences, exchange tips and recommendations, and gain inspiration from their adventures. It's a great way to build a community of like-minded explorers.",
    },
    {
      question: "How do I hire a local guide through the app?",
      answer:
        " Hiring a local guide is easy. Browse the list of qualified guides, read reviews and ratings, and select the guide that best suits your needs. You can then book their services securely through our payment processing system.",
    },
    {
      question:
        "Is my payment information secure when booking guides or classes?",
      answer:
        "Yes, your payment information is secure. We use a secure payment processing system to ensure the safety of your transactions when booking guides or classes.",
    },
    {
      question: " How can I create a personalized profile on the app?",
      answer:
        "Creating a profile is simple. Go to your app settings, select 'Profile,' and provide your personal information, interests, and outdoor activity preferences. Your profile is a reflection of your adventure persona and helps you connect with others who share your interests.",
    },
    {
      question: "Are there any current job openings for guides?",
      answer:
        "Yes, we're currently hiring guides who are great at storytelling, humble, and kind with people. If you fit this description and are passionate about the outdoors, please register yourself and explore opportunities to become a guide with us.",
    },
    {
      question: "Can I use the app on both iOS and Android devices?",
      answer:
        "Yes, 'My App Name' is available for both iOS and Android devices. You can download it from the App Store or Google Play Store.",
    },
    {
      question:
        "Can I use the app while offline, such as in remote areas with no internet connection? How does this work?",
      answer:
        "Yes, you can use several key features of the app while offline, making it an ideal companion for adventures in remote areas with limited or no internet connectivity. Here's how it works:",
    },
    {
      question: "Do I need to pay for this service?",
      answer:
        "Our basic service is free to use, but we also offer premium plans with additional features.",
    },
  ];
  const navigate = useNavigate();
  const leftFaqs = faqs.slice(0, 5);
  const rightFaqs = faqs.slice(5, 10);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });
  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  async function Submit(e) {
    e.preventDefault();
    const formEle = document.querySelector("#form");
    const formDatab = new FormData(formEle);
    const inputValue = formDatab.get(
      selectedOption === "email" ? "Email" : "Phone"
    );

    if (!inputValue) {
      handleOpenDialog("Failure", "Input field is empty. Kindly fill it.");
      return;
    }
    if (selectedOption === "email") {
      formDatab.append("gmail", inputValue);
    } else if (selectedOption === "phone") {
      formDatab.append("phone", inputValue);
    }
    const requestOptions = {
      method: "POST",
      body: formDatab,
      redirect: "follow",
    };

    // try {
    //   const response = await fetch(
    //     "https://script.google.com/macros/s/AKfycbx6rDj7_l86jzHF19LMEDzIXxIYzJ01QMJ2P0NTrX8t9QMRV2aeU3O_B29jFIOeGaJi/exec",
    //     {
    //       method: "POST",
    //       body: formDatab,
    //     }
    //   );
    //   if (response.ok === true) {
    //     handleOpenDialog(
    //       "Success",
    //       "Thank you for submitting! Our team will get in touch with you."
    //     );
    //   } else {
    //     handleOpenDialog("Failure", "Failed to submit. Please try again.");
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    //   handleOpenDialog("Error", "An error occurred. Please try again.");
    // }
    try {
      const response = await fetch(
        "https://orca-app-pgxkr.ondigitalocean.app/website/website-enquiry/",
        requestOptions
      );

      if (response.ok) {
        const result = await response.text();
        console.log("Success:", result);
        handleOpenDialog(
          "Success",
          "Thank you for submitting! Our team will get in touch with you."
        );
      } else {
        const errorText = await response.text();
        console.error("Error:", errorText);
        handleOpenDialog("Error", "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      handleOpenDialog("Error", "An error occurred. Please try again.");
    }
  }

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const handleToggleAccordion = (index) => {
    if (index === openAccordion) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };
  return (
    <div id="faq">
      <div className="faq_container">
        <div className="faq_left_div">
          <h4>Frequently Asked Question (FAQ)</h4>
          <p>Related to your app and their corresponding answers</p>
          <div>
            {leftFaqs.map((item, index) => (
              <Accordion
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={openAccordion === index}
                onToggle={() => handleToggleAccordion(index)}
              />
            ))}
          </div>
        </div>

        <div className="faq_right_div">
          <div>
            {rightFaqs.map((item, index) => (
              <Accordion
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={openAccordion === index + leftFaqs.length}
                onToggle={() => handleToggleAccordion(index + leftFaqs.length)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="faq_container2">
        <h4 className="dis_b">
          Get the <b>GoWild </b> App
        </h4>
        <div className="rotate_mobile_left">
          <img src={require("../Images/rotate_mob.jpeg")} alt="#" />
        </div>
        <div className="rotate_mobile_right">
          <h4 className="dis_n">
            Get the <b>GoWild </b>App
          </h4>
          <p>
            We will send you a link, open it on your phone'to download the app
          </p>
          <span>
            <input
              type="radio"
              id="email"
              name="contactMethod"
              value="email"
              checked={selectedOption === "email"}
              onChange={handleOptionChange}
              required
            />{" "}
            <lable>Email</lable>
            <input
              type="radio"
              id="phone"
              name="contactMethod"
              value="phone"
              checked={selectedOption === "phone"}
              onChange={handleOptionChange}
              required
            />{" "}
            <lable>Phone</lable>
          </span>
          <br />
          <form id="form" method="post" name="form">
            <div className="flex">
              <input
                type="email"
                placeholder={`${
                  selectedOption === "email" ? "Email" : "Phone"
                }`}
                id={`${selectedOption === "email" ? "Email" : "Phone"}`}
                name={`${selectedOption === "email" ? "Email" : "Phone"}`}
                required
              />
              <button onClick={Submit}>Share App Link</button>
            </div>
            {/* {showPopup && (
              <div
                style={{
                  display: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  alignItems: "center",
                  borderRadius: "8px",
                  margin: "8px 0",
                  justifyContent: "center",
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#31e3ae",
                    color: "#000",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p>
                    Thank you for submitting! Our team will get in touch with
                    you.
                  </p>
                  <button
                    style={{
                      marginTop: "2px",
                      backgroundColor: "#FFF",
                      color: "#31e3ae",
                      padding: "0.5rem 0.75rem",
                      borderRadius: "0.375rem",
                    }}
                    onClick={togglePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            )} */}
            <DialogBox
              open={openDialog}
              onClose={handleCloseDialog}
              title={dialogContent.title}
              content={dialogContent.message}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
{
  /* <div className="faq_container">
<div className="faq_left_div">
  <h4>Frequently Asked Question (FAQ)</h4>
  <p>Related to your app and their corresponding answers</p>
  <div>
    <div className="faq_back">
      <p>What is 'App Name', and how does it work?</p>
      <img onClick={toggleTooltip} src={imageSrc} alt="#" />
    </div>
    {isTooltipVisible && (
      <div className="pop_up_text">
        <p>
          'Go Wild' is a travel and outdoor activity platform that helps
          you discover exciting places, record your adventures, connect
          with fellow adventurers, attend activity classes, and hire
          experienced local guides. It works by using geolocation
          services to find popular places nearby, allowing you to
          explore and document your outdoor experiences.
        </p>
      </div>
    )}
    <div className="faq_back">
      <p>What is 'App Name', and how does it work?</p>
      <img onClick={toggleTooltip} src={imageSrc} alt="#" />
    </div>

    <div className="faq_back">
      <p>How can i record my outdoor adventures using the app?</p>
      <img src={require("../Images/circle-right.png")} alt="#" />
    </div>
    <div className="faq_back">
      <p>
        What the benefits of connecting with other users on the apps?
      </p>
      <img src={require("../Images/circle-right.png")} alt="#" />
    </div>
    <div className="faq_back">
      <p>How do i hire a local guide through the app?</p>
      <img src={require("../Images/circle-right.png")} alt="#" />
    </div>
  </div>
</div>

<div className="faq_right_div">
  <div className="faq_back">
    <p>Is my payment information secure when booking guides or...</p>
    <img src={require("../Images/circle-right.png")} alt="#" />
  </div>
  <div className="faq_back">
    <p>How can i create a personaized profile on the app?</p>
    <img src={require("../Images/circle-right.png")} alt="#" />
  </div>
  <div className="faq_back dis_n">
    <p>Are there any current jobopenings for guides?</p>
    <img src={require("../Images/circle-right.png")} alt="#" />
  </div>
  <div className="faq_back dis_n">
    <p>Can i use the app on both IOS and Android devices?</p>
    <img src={require("../Images/circle-right.png")} alt="#" />
  </div>
  <div className="faq_back dis_n">
    <p>
      Can i use the app while offline, such as in remote areas with...
    </p>
    <img src={require("../Images/circle-right.png")} alt="#" />
  </div>
</div>
</div> */
}
