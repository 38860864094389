import React, { useState } from "react";
import "../Style/Footer.css";
import { Link } from "react-router-dom";
import DialogBox from "./DialogBox";

export default function Footer() {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });
  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  async function Submit1(e) {
    e.preventDefault();
    const formEle = document.querySelector("#form1");
    const formDatab = new FormData(formEle);
    const inputValue = formDatab.get("Email");
    if (!inputValue) {
      handleOpenDialog("Failure", "Input field is empty. Kindly fill it.");

      return;
    }
    formDatab.append("gmail", inputValue);

    const requestOptions = {
      method: "POST",
      body: formDatab,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://orca-app-pgxkr.ondigitalocean.app/website/website-enquiry/",
        requestOptions
      );

      if (response.ok) {
        const result = await response.text();
        console.log("Success:", result);
        handleOpenDialog(
          "Success",
          "Thank you for submitting! Our team will get in touch with you."
        );
      } else {
        const errorText = await response.text();
        console.error("Error:", errorText);
        handleOpenDialog("Error", "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      handleOpenDialog("Error", "An error occurred. Please try again.");
    }
    //  Navigate('/')
    // togglePopup();
  }
  return (
    <>
      <div className="footer_back_color">
        <div className="footer_width">
          <div className="footer">
            <div className="left">
              <img src={require("../Images/footer_logo.png")} alt="#" />
              <p>
                Welcome to 'Go WIld ,' where adventure knows no bounds. With us,
                you'll embark on journeys of discovery, where the unexplored
                becomes your playground. Dive into the beauty of untouched
                landscapes, wander through hidden trails, and unlock the secrets
                of the world's most extraordinary destinations. Unleash your
                inner explorer and redefine what's possible. Begin your odyssey
                today.
              </p>
              <a
                href="https://youtube.com/@GoWildExplorer?si=_HkwM3Zb04u6oDEW"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://x.com/GoWildexplorer?s=20"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/gowild_explorer/?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://wa.me/+918979951459"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-whatsapp"></i>
              </a>

              {/* <img src={require("../Images/Frame (1).png")} alt="" /> */}
            </div>
            <div className="middleAndSubcribe_wrapper">
              <div className="middle">
                <div className="middle_text">
                  <ul>
                    <li className="head_menu">
                      <p className="title_p">Address</p>
                    </li>
                    <li style={{ lineHeight: "1" }}>Data Burj</li>
                    <li style={{ lineHeight: "1" }}>Post-Madaura</li>
                    <li style={{ lineHeight: "1" }}>Baldeo, Mathura</li>
                    <li style={{ lineHeight: "1" }}>Uttar Pradesh</li>
                    <li style={{ lineHeight: "1" }}>Pincode-281301</li>
                  </ul>
                  <ul className="contact-list">
                    <li className="head_menu">Contact</li>
                    <span className="center1">
                      <img
                        className="whats_icon"
                        src={require("../Images/whats_i.png")}
                        alt=""
                      />
                      <a
                        href="https://wa.me/+918979951459"
                        className="whatsapp-link"
                      >
                        +91 89799 51459
                      </a>
                    </span>
                    <br />
                    <span className="center2">
                      <img
                        className="whats_icon"
                        src={require("../Images/email_i.png")}
                        alt=""
                      />
                      <a
                        href="mailto:gowildexplorer@gmail.com"
                        style={{ borderBottom: "1px solid white" }}
                      >
                        gowildexplorer@gmail.com
                      </a>
                    </span>
                  </ul>
                </div>
                <div className="about_us_section">
                  <ul>
                    <li className="head_menu">
                      <p className="title_p">About Us</p>
                    </li>
                    <li>Home</li>
                    <li>Features</li>
                    <li>About Us</li>
                    <li>Contact Us</li>
                  </ul>
                </div>
                <div className="become_partner_section">
                  <ul>
                    <li className="become_partner_title">
                      <p className="title_p">Become a partner</p>
                    </li>
                    <li className="children">
                      <Link className="stay_guide_link" to={"/guide"}>
                        Guide
                      </Link>
                    </li>
                    <li className="children">
                      <Link className="stay_guide_link" to={"/stay_guide"}>
                        Stay
                      </Link>
                    </li>
                    <li className="children">
                      <Link className="stay_guide_link" to={"/rental_vheicle"}>
                        Rental Vheicle
                      </Link>
                    </li>
                    <li className="children">
                      <Link className="stay_guide_link" to={"/self_camping"}>
                        Self Camping
                      </Link>
                    </li>
                    <li className="children">
                      <Link className="stay_guide_link" to={"/cafe"}>
                        Cafe
                      </Link>
                    </li>
                    <li className="children">
                      <Link className="stay_guide_link" to={"/shop"}>
                        Shop
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right">
                <div className="footer_div">
                  <h3>Subscribe!</h3>
                  <p>Subscribe to our Website and stay updated</p>
                  <form id="form1" method="post" name="form">
                    <div>
                      <input
                        type="email"
                        placeholder="Email"
                        id="Email"
                        name="Email"
                        style={{ color: "white" }}
                        required
                      />
                    </div>
                    <br />
                    <button onClick={Submit1}>Submit</button>
                    <DialogBox
                      open={openDialog}
                      onClose={handleCloseDialog}
                      title={dialogContent.title}
                      content={dialogContent.message}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <p className="hr_line" />
          <div className="copy_right_footer">
            <p>Copyrights@2023 All rights reserved</p>
            <p>
              <span>
                <Link to={"/termsAndCondition"}>Terms Of Services </Link>
              </span>
              <span>
                <Link to={"/privacy"}>| Privacy Policy</Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
