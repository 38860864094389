import axios from "axios";
import { BASE_URL } from "../../constants/constant";

export const imagePostApi = async (data) => {
  const config = {
    headers: {
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(`${BASE_URL}/data/image/`, data, config);

  return response;
};

export const imageDeleteApi = async (data) => {
  const config = {
    headers: {
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.delete(
    `${BASE_URL}/data/image/${data}/`,
    config
  );

  return response;
};
