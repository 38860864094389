import axios from "axios";
import { BASE_URL } from "../../constants/constant";

export const getCitiesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.get(
    `https://preprod.dashboard.gowild.co.in/data/city`,
    config
  );

  return response;
};

export const searchCitiesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };
  const response = await axios.get(
    `https://preprod.dashboard.gowild.co.in/dashboard/place-home/?page=1&name=${data}&page_size=${12}`,
    config
  );
  return response;
};

// export const postPlacesApi = async (data) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
//     },
//   };

//     const response = await axios.post(`${BASE_URL}/place-home`, data, config);

//   return response;
// };

// export const searchPlacesApi = async (data) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
//     },
//   };

//   const response = await axios.get(
//     `${BASE_URL}/dashboard/place-home/?name=${data}`,
//     config
//   );

//   return response;
// };
