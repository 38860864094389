import axios from "axios";
import { BASE_URL } from "../../constants/constant";
export const selfCampingPostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(
    `${BASE_URL}/bookings/selfcamping/`,
    data,
    config
  );

  return response;
};

export const cafePostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(`${BASE_URL}/bookings/cafe/`, data, config);

  return response;
};

//  RENTEL VHEICLE SUBCETOGRY

export const renetlSubCategoryGetApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/bookings/rent-vehicle-subcategory/`,
    config
  );

  return response;
};

export const rentelVheiclePostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(
    `${BASE_URL}/bookings/rent-vehicle/`,
    data,
    config
  );

  return response;
};

export const stayPostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(`${BASE_URL}/bookings/stay/`, data, config);

  return response;
};

// Amenities

export const amenitiesGetApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.get(`${BASE_URL}/bookings/stay-amenity`, config);

  return response;
};

// Stay Category Id

export const stayCategoryGetApi = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/bookings/stay-category`,
    config
  );

  return response;
};

export const bookingShopgearPostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.post(`${BASE_URL}/bookings/shop/`, data, config);

  return response;
};

// Booking shopgear facilities

export const bookingShopgearFacitiliesApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/bookings/shopgear-facility`,

    config
  );

  return response;
};

// Booking shopgear facilities

export const bookingShopgearLengthApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/bookings/cloth-length`,

    config
  );

  return response;
};

// export const searchPlacesApi = async (data) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Token fa806db41dfaf50a72f41834b131bb9160c429ee`,
//     },
//   };

//   const response = await axios.get(
//     `${BASE_URL}/dashboard/place-home/?name=${data}`,
//     config
//   );

//   return response;
// };
