import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { countries } from "../LocationData";
import DialogBox from "../DialogBox";
import "../../Style/StayGuide.css";

import { LuImagePlus } from "react-icons/lu";

import { RiDeleteBin7Line } from "react-icons/ri";
import { CiCirclePlus } from "react-icons/ci";
import { imagePostApi, imageDeleteApi } from "../../services/apis/imagesApi";
import { getCitiesApi } from "../../services/apis/locationApi";
import { rentelVheiclePostApi } from "../../services/apis/enquairyApi";
import { socialMediaPostApi } from "../../services/apis/socialMediaApi";
import { renetlSubCategoryGetApi } from "../../services/apis/enquairyApi";
import { MdOutlineGpsFixed } from "react-icons/md";
import CityDropdown from "../autocomplete/CityDropdown";

const RentalVheicleForm = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [formData, setFormData] = useState({
    shopName: "",
    price: "",
    phone: "",
    alternate_number: "",
    email: "",
    address: "",
    Latitude: "",
    Longitude: "",
    facebook: "",
    youtube: "",
    twitter: "",
    instagram: "",
    Description: "",
  });
  const [image, setImage] = useState(null);
  const [photos, setPhotos] = useState([
    { id: uuidv4(), file: null, url: null },
  ]);
  const [photoIds, setPhotoIds] = useState([]);
  const [photosError, setPhotosError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [citiesData, setCitiesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rentleSubCate, setRentleSubCate] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [cityId, setCityId] = useState("");
  const [cityError, setCityError] = useState("");

  const [cityName, setCityName] = useState("");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });
  const [error, setError] = useState(null);
  const [vehicle, setVehicle] = useState([
    { room_type: "", Price: "", payment_type: "" },
  ]);
  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (location.latitude && location.longitude) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Latitude: location.latitude,
        Longitude: location.longitude,
      }));
    }
  }, [location, setFormData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCitiesApi(currentPage);
        if (response.status === 200) {
          setCitiesData(response?.data?.results);
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog("Opps, Something went wrong !");
      }
      try {
        const response = await renetlSubCategoryGetApi();
        if (response.status === 200) {
          setRentleSubCate(response?.data?.results);
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog("Opps, Something went wrong !");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const ids = rentleSubCate.map((subCate) => subCate.id);
    setSubCategoryIds(ids);
  }, [rentleSubCate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "titles" || name === "first_name" || name === "last_name") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        name: `${prevFormData.titles} ${prevFormData.first_name} ${prevFormData.last_name}`,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setFormErrors({
      ...formErrors,
      [name]: "",
    });

    const urlRegex =
      /^(http(s)?:\/\/)?(www\.)?(instagram|facebook|twitter|youtube)\.com\/[a-zA-Z0-9_]+$/;

    if (["instagram", "facebook", "twitter", "youtube"].includes(name)) {
      if (value && !urlRegex.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `Invalid ${name} URL format`,
        }));
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (cityId === "") {
      setCityError("This field is required.");
    } else {
      setCityError("");
    }

    const urlRegex =
      /^(http(s)?:\/\/)?(www\.)?(instagram|facebook|twitter|youtube)\.com\/[a-zA-Z0-9_]+$/;

    if (!urlRegex.test(formData.facebook) && !formData.facebook === "") {
      errors.facebook = "Invalid Facebook URL format";
    }

    if (!urlRegex.test(formData.twitter) && !formData.twitter == "") {
      errors.twitter = "Invalid Twitter URL format";
    }

    if (!urlRegex.test(formData.youtube) && !formData.youtube == "") {
      errors.youtube = "Invalid Youtube URL format";
    }

    if (!urlRegex.test(formData.instagram) && !formData.instagram == "") {
      errors.instagram = "Invalid Instgram URL format";
    }

    // if (!validateEmail(formData.email)) {
    //   errors.email = "Invalid email format";
    // }
    // const phoneRegex = /^[0-9]{10}$/;
    // if (!phoneRegex.test(formData.phone)) {
    //   errors.phone = "Invalid phone number format. It should be 10 digits.";
    // }

    // if (
    //   formData.alternate_number &&
    //   !phoneRegex.test(formData.alternate_number)
    // ) {
    //   errors.alternate_number =
    //     "Invalid Alternate number format. It should be 10 digits.";
    // }

    // if (!image) {
    //   errors.image = "Image is required.";
    // }

    const hasValidPhoto = photos.some((photo) => photo.file !== null);
    if (!hasValidPhoto) {
      setPhotosError("At least one image is required.");
    }

    for (const key in formData) {
      if (
        key !== "aadhar_number" &&
        key !== "whatsapp_number" &&
        key !== "email" &&
        key !== "phone" &&
        key !== "dob" &&
        key !== "alternate_number"
      ) {
        if (
          !formData[key] &&
          ["instagram", "facebook", "youtube", "twitter"].indexOf(key) === -1
        ) {
          errors[key] = "This field is required.";
        }
      }
    }

    // vehicle.forEach((room, index) => {
    //   if (!room.room_type) {
    //     errors[`room_type_${index}`] = "Room type is required.";
    //   }
    //   if (!room.Price) {
    //     errors[`Price_${index}`] = "Price is required.";
    //   }
    //   if (!room.payment_type) {
    //     errors[`payment_type_${index}`] = "Payment type is required.";
    //   }
    // });

    setFormErrors(errors);

    // If there are validation errors, don't submit the request
    if (Object.keys(errors).length > 0) {
      return;
    }

    // Prepare form data
    const formdata = new FormData();

    formdata.append("name", formData.shopName);
    formdata.append("city", cityId);
    formdata.append("price", formData.price);
    formdata.append("whatsapp", formData.phone);
    // formdata.append("sub_category", subCategoryIds);
    formdata.append("email", formData.email);
    formdata.append("address", formData.address);
    formdata.append("lat", formData.Latitude);
    formdata.append("lon", formData.Longitude);
    formdata.append("description", formData.Description);
    formdata.append("views_count", 15);
    formdata.append("images", photoIds);
    // formdata.append("vehicle", vehicle);

    const submitData = async () => {
      try {
        const response = await rentelVheiclePostApi(formdata);
        if (response.status === 201) {
          if (formData.instagram) {
            const socialMediaPaylod = {
              rent_vehicle: response?.data?.id,
              sc_type: "Instagram",
              link: formData.instagram,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          if (formData.facebook) {
            const socialMediaPaylod = {
              rent_vehicle: response?.data?.id,
              sc_type: "Facebook",
              link: formData.facebook,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          if (formData.youtube) {
            const socialMediaPaylod = {
              rent_vehicle: response?.data?.id,
              sc_type: "Youtube",
              link: formData.youtube,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          if (formData.twitter) {
            const socialMediaPaylod = {
              rent_vehicle: response?.data?.id,
              sc_type: "Twitter",
              link: formData.twitter,
            };
            try {
              const response = await socialMediaPostApi(socialMediaPaylod);
            } catch (error) {
              setError(error.response.data.detail);
              handleOpenDialog(error.response.data.detail);
            }
          }
          handleOpenDialog("Form Submited successfully !");
          handleReset();
        } else {
          handleOpenDialog(
            "Failure",
            "Failed to submit the form data. Please try again."
          );
        }
      } catch (error) {
        setError(error.response.data.detail);
        handleOpenDialog("Oops! something Went Wrong, Please try again");
      }
    };
    submitData();
  };

  const fileInputRef = useRef(null);

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setImage(URL.createObjectURL(selectedFile));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleRoomChange = (index, event) => {
    const { name, value } = event.target;
    const updatedVehicle = vehicle.map((vehicle, i) => {
      if (i === index) {
        return { ...vehicle, [name]: value };
      }
      return vehicle;
    });

    setVehicle(updatedVehicle);

    setFormErrors({
      ...formErrors,
      [`${name}_${index}`]: "",
    });
  };

  const addRoom = (e) => {
    e.preventDefault();
    setVehicle((prevVehicle) => [
      ...prevVehicle,
      { room_type: "", Price: "", payment_type: "" },
    ]);
  };

  const removeRoom = (index) => {
    const updatedVehicle = vehicle.filter((_, i) => i !== index);
    setVehicle(updatedVehicle);
  };

  const addPhotoField = () => {
    if (photos.length < 5) {
      setPhotos((prevPhotos) => [
        ...prevPhotos,
        { id: uuidv4(), file: null, url: null },
      ]);
    }
  };

  const handlePhotoUpload = async (index, event) => {
    const selectedFile = event.target.files[0];
    const newPhotos = photos.map((photo, idx) =>
      idx === index
        ? {
            ...photo,
            file: selectedFile,
            url: URL.createObjectURL(selectedFile),
            uploaded: false,
          }
        : photo
    );
    setPhotos(newPhotos);
    setPhotosError("");
    try {
      const imageData = new FormData();
      imageData.append("image", selectedFile);

      const response = await imagePostApi(imageData);
      const photoId = response.data.id;

      setPhotoIds((prevIds) => [...prevIds, photoId]);
      setPhotos((prevPhotos) =>
        prevPhotos.map((p, i) => (i === index ? { ...p, uploaded: true } : p))
      );
    } catch (error) {
      setError("Error uploading photo");
    }
  };

  const handleDeletePhoto = async (index) => {
    const photoToDelete = photos[index];

    if (photoToDelete && photoToDelete.file) {
      try {
        await imageDeleteApi(photoIds[index]);
        setPhotoIds((prevIds) => prevIds.filter((_, idx) => idx !== index));
      } catch (error) {
        setError("Error deleting photo");
      }
    }

    setPhotos((prevPhotos) => prevPhotos.filter((_, idx) => idx !== index));
  };

  const handleReset = () => {
    setFormData({
      shopName: "",
      city: "",
      price: "",
      phone: "",
      alternate_number: "",
      email: "",
      address: "",
      Latitude: "",
      Longitude: "",
      facebook: "",
      youtube: "",
      twitter: "",
      instagram: "",
      Description: "",
    });
    setImage(null);
    setPhotos([{ id: uuidv4(), file: null, url: null }]);
    setVehicle([{ room_type: "", Price: "", payment_type: "" }]);
    setSelectedCountry("");
    setSelectedState("");
    setFormErrors({});
  };

  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    setLocation({ latitude, longitude });
  };

  const handleError = (error) => {
    setError(error.message);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h5"
          align="left"
          color="black"
          fontWeight={700}
          gutterBottom
        >
          Kindly fill up these Details
        </Typography>
        <div>
          {/* <div>
            <input
              ref={fileInputRef}
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <button
              className="stay_file_uploader"
              type="button"
              onClick={handleButtonClick}
            >
              {image ? (
                <img src={image} alt="Selected" className="stay_image" />
              ) : (
                <div>
                  <LuImagePlus size={50} style={{ color: "lightgray" }} />
                  <p>Add Image</p>
                </div>
              )}
            </button>
          </div> */}
          <form onSubmit={handleSubmit}>
            <Grid container maxWidth="lg" spacing={2} marginTop={4}>
              <Grid item xs={12} sm={6} md={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Rental Name
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Enter your Rental Name"
                  name="shopName"
                  multiline
                  value={formData.shopName}
                  onChange={handleInputChange}
                />
                {formErrors.shopName && (
                  <Typography variant="body2" color="error">
                    {formErrors.shopName}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  City
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl fullWidth>
                  {/* <Select
                    name="city"
                    value={formData?.city}
                    onChange={handleInputChange}
                  >
                    {citiesData &&
                      citiesData?.length > 0 &&
                      citiesData?.map((items, cityIndex) => (
                        <MenuItem key={items?.city_id} value={items?.city_id}>
                          {items?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {formErrors.city && (
                    <Typography variant="body2" color="error">
                      {formErrors.city}
                    </Typography>
                  )} */}
                  <CityDropdown
                    cityValue={cityName}
                    setCityId={setCityId}
                    style={"stay"}
                    cityName={cityId}
                    error={cityError}
                    setCityError={setCityError}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Price
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Enter Price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  type="number"
                />
                {formErrors.price && (
                  <Typography variant="body2" color="error">
                    {formErrors.price}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={5} sm={5} md={5}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Latitude
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  name="Latitude"
                  placeholder="Latitude"
                  value={formData.Latitude}
                  onChange={handleInputChange}
                />
                {formErrors.Latitude && (
                  <Typography variant="body2" color="error">
                    {formErrors.Latitude}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5} sm={5} md={5}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Longitude
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  name="Longitude"
                  placeholder="Longitude"
                  value={formData.Longitude}
                  onChange={handleInputChange}
                />
                {formErrors.Longitude && (
                  <Typography variant="body2" color="error">
                    {formErrors.Longitude}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <div className="geoLocatoin_container">
                  <MdOutlineGpsFixed
                    onClick={getLocation}
                    size={30}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </FormLabel>
                <TextField
                  fullWidth
                  name="phone"
                  placeholder="+91"
                  value={formData.phone ? `${formData.phone}` : ""}
                  onChange={handleInputChange}
                />
                {formErrors.phone && (
                  <Typography variant="body2" color="error">
                    {formErrors.phone}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Alternate Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </FormLabel>
                <TextField
                  fullWidth
                  name="alternate_number"
                  placeholder="+91"
                  value={
                    formData.alternate_number
                      ? `${formData.alternate_number}`
                      : ""
                  }
                  onChange={handleInputChange}
                />
                {formErrors.alternate_number && (
                  <Typography variant="body2" color="error">
                    {formErrors.alternate_number}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Email
                  {/* <span style={{ color: "red" }}>*</span> */}
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="User@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {formErrors.email && (
                  <Typography variant="body2" color="error">
                    {formErrors.email}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Address
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Enter you address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
                {formErrors.address && (
                  <Typography variant="body2" color="error">
                    {formErrors.address}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Description
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  fullWidth
                  placeholder="Description"
                  name="Description"
                  multiline
                  rows={6}
                  value={formData.Description}
                  onChange={handleInputChange}
                />
                {formErrors.Description && (
                  <Typography variant="body2" color="error">
                    {formErrors.Description}
                  </Typography>
                )}
              </Grid>

              {/* <Container sx={{ mt: 4 }} className="add_room_container">
                {vehicle?.map((vehicle, index) => (
                  <div key={index} className="room_input_wrapper">
                    <Grid container maxWidth="lg" spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormLabel
                          sx={{
                            color: "black",
                            fontWeight: "700",
                            marginLeft: 1.5,
                          }}
                        >
                          Vehicle Type <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <TextField
                          fullWidth
                          placeholder="Enter Your Vehicle Type"
                          name="room_type"
                          value={vehicle.room_type}
                          onChange={(event) => handleRoomChange(index, event)}
                          error={Boolean(formErrors[`room_type_${index}`])}
                          helperText={formErrors[`room_type_${index}`]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormLabel
                          sx={{
                            color: "black",
                            fontWeight: "700",
                            marginLeft: 1.5,
                          }}
                        >
                          Price <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <TextField
                          fullWidth
                          placeholder="Price"
                          name="Price"
                          type="number"
                          value={vehicle.Price}
                          onChange={(event) => handleRoomChange(index, event)}
                          error={Boolean(formErrors[`Price_${index}`])}
                          helperText={formErrors[`Price_${index}`]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormLabel
                          sx={{
                            color: "black",
                            fontWeight: "700",
                            marginLeft: 1.5,
                          }}
                        >
                          Payment Type <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            name="payment_type"
                            value={vehicle.payment_type}
                            onChange={(event) => handleRoomChange(index, event)}
                            error={Boolean(formErrors[`payment_type_${index}`])}
                          >
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="Online">Online</MenuItem>
                          </Select>
                        </FormControl>
                        {formErrors[`payment_type_${index}`] && (
                          <Typography variant="body2" color="error">
                            {formErrors[`payment_type_${index}`]}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        sx={{ marginTop: 3.5 }}
                        className="delete_room_icon"
                      >
                        <div className="delete_room_icon_child">
                          <RiDeleteBin7Line
                            size={30}
                            color="red"
                            onClick={() => removeRoom(index)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                <div className="add_room_button_container">
                  <button onClick={addRoom} className="add_room_button">
                    Add More
                  </button>
                </div>
              </Container> */}

              <div className="multiImg_main_container">
                <Grid sx={{ mt: 3 }}>
                  <FormLabel
                    sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                  >
                    Upload Photos
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                </Grid>
                <div className="multiImg_container">
                  {photos.map((photo, index) => (
                    <div key={photo.id}>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id={`photo-upload-${index}`}
                        type="file"
                        onChange={(event) => handlePhotoUpload(index, event)}
                      />

                      <div className="additional_photos_wrapper">
                        <button
                          className="stay_file_uploader"
                          onClick={() =>
                            document
                              .getElementById(`photo-upload-${index}`)
                              .click()
                          }
                          type="button"
                        >
                          {photo.url ? (
                            <img
                              src={photo.url}
                              alt={`Additional ${index + 1}`}
                              className="stay_image"
                            />
                          ) : (
                            <div>
                              <LuImagePlus
                                size={50}
                                style={{ color: "lightgray" }}
                              />
                              <p>Add Image</p>
                            </div>
                          )}
                        </button>
                        {photos.length > 1 && (
                          <RiDeleteBin7Line
                            size={30}
                            color="red"
                            onClick={() => handleDeletePhoto(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  {photos.length < 5 && (
                    <CiCirclePlus
                      size={60}
                      style={{
                        marginBottom: "22px",
                        color: "#31E3AE",
                        height: "50px",
                        alignSelf: "center",
                        cursor: "pointer",
                      }}
                      onClick={addPhotoField}
                    />
                  )}
                </div>
                {photosError && (
                  <p style={{ color: "#d32f2f", fontSize: "15px" }}>
                    {photosError}
                  </p>
                )}
              </div>
              <div className="socialmedia_main_container">
                <FormLabel
                  sx={{ color: "black", fontWeight: "700", marginLeft: 1.5 }}
                >
                  Social Media URL
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Grid
                  container
                  maxWidth="lg"
                  spacing={2}
                  style={{ marginTop: "1px" }}
                >
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Facebook"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="facebook"
                      placeholder="Enter Url"
                      value={formData.facebook}
                      onChange={handleInputChange}
                    />
                    {formErrors.facebook && (
                      <Typography variant="body2" color="error">
                        {formErrors.facebook}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Instagram"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="instagram"
                      placeholder="Enter Url"
                      value={formData.instagram}
                      onChange={handleInputChange}
                    />
                    {formErrors.instagram && (
                      <Typography variant="body2" color="error">
                        {formErrors.instagram}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Youtube"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="youtube"
                      placeholder="Enter Url"
                      value={formData.youtube}
                      onChange={handleInputChange}
                    />
                    {formErrors.youtube && (
                      <Typography variant="body2" color="error">
                        {formErrors.youtube}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      fullWidth
                      placeholder="Twitter"
                      inputProps={{ readOnly: true }}
                      style={{ backgroundColor: "#e4eef2" }}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}>
                    <TextField
                      fullWidth
                      name="twitter"
                      placeholder="Enter Url"
                      value={formData.twitter}
                      onChange={handleInputChange}
                    />
                    {formErrors.twitter && (
                      <Typography variant="body2" color="error">
                        {formErrors.twitter}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </div>
              <Container className="stay_buton_container">
                <div className="stay_buton_container_childs">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#31E3AE",
                      border: "1px solid #31E3AE",
                      marginBottom: "22px",
                      padding: "8px 30px ",
                    }}
                    onClick={handleReset}
                  >
                    Reset All
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      backgroundColor: "#31E3AE",
                      marginBottom: "22px",
                      padding: "8px 30px ",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Container>
            </Grid>
          </form>
          <DialogBox
            open={openDialog}
            onClose={handleCloseDialog}
            title={dialogContent.title}
            content={dialogContent.message}
          />
        </div>
      </Container>
    </>
  );
};

export default RentalVheicleForm;
