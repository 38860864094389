import React from "react"; // Import FontAwesome icons

export default function ActButton({ text }) {
  const activitiesArray = text;
  return (
    <div style={{ marginTop: "4px" }}>
      {activitiesArray.map((activity, index) => (
        <button
          className="actcenterbtn"
          key={index}
          style={{
            backgroundColor: "#A7DBF5",
            color: "black",
            border: "none",
            cursor: "pointer",
            fontSize: "11px",
            borderRadius: "7px",
            padding: "4px",
            paddingRight: "4px", // Adjusted the right padding for the icon
            margin: "2px",
            position: "relative", // Added relative positioning to the button
          }}
        >
          {activity.length > 18 ? activity.slice(0, 19) + "..." : activity}
          {/* <span
            style={{
              position: "absolute", // Absolute positioning for the icon
              top: "50%",
              right: "10px", // Adjust the right position
              transform: "translateY(-50%)", // Center the icon vertically
              background: "#E5E5E5", // Add background color to create the gray circle
              borderRadius: "50%", // Make it a circle
              width: "15px", // Set the circle's width and height
              height: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              className="fas fa-times" // Use the correct FontAwesome class for the "times" icon
              style={{
                color: "black",
                fontSize: "12px",
              }}
            ></i>
          </span> */}
        </button>
      ))}
    </div>
  );
}
