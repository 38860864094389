// import React, { useState } from "react";
// import "../Style/Faq.css";

// export default function Accordion({ question, answer, isOpen, onToggle }) {
//   return (
//     <div>
//       <div className={`faq_back ${isOpen ? "open" : ""}`} onClick={onToggle}>
//         <p>{question}</p>
//         <img
//           src={
//             isOpen
//               ? require("../Images/Down_Arrow.png")
//               : require("../Images/circle-right.png")
//           }
//           alt="#"
//         />
//       </div>
//       {isOpen && (
//         <div className="pop_up_text">
//           <p>{answer}</p>
//         </div>
//       )}
//     </div>
//   );
// }
import React, { useState } from "react";
import "../Style/Faq.css";

export default function Accordion({ question, answer, isOpen, onToggle }) {
  return (
    <div>
      <div
        className={`${isOpen ? "faq_back" : "faq_back1"}`}
        onClick={onToggle}
      >
        <p>{question}</p>
        <img
          src={
            isOpen
              ? require("../Images/Down_Arrow.png")
              : require("../Images/circle-right.png")
          }
          alt="#"
        />
      </div>
      <div className={`pop_up_text ${isOpen ? "open" : ""}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
}
